<template>
  <div class="event-review">
    <div class="title">{{ I18n.t("sport_events.request_message.title") }}</div>
    <div class="event-review-wrap">
      <p>{{ this.text }}</p>
    </div>

    <div class="close-button-wrap">
      <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
        {{ I18n.t("common.buttons.close") }}
      </a>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      text: String
    },
    data: function() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .event-review {
    height: 100%;
  }

  .event-review-wrap {
    height: 60%;
    width: 229px;
    margin: 0px 50px;
    margin-top: 20px;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 450;
    padding-top: 15px;
  }

  #review-textarea {
    font-size: 16px !important;
  }

  .submit-button-wrap {
    text-align: center;
    margin-top: 20px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }

  .close-button-wrap {
    text-align: center;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }
</style>
