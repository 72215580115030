<template>
  <div class="sport-item col-lg align-self-lg-start align-self-lg-start" v-bind:class="selectedClass">
    <div class="sport-item__name">{{ I18n.t(`sport_names.${sport.code}`) }}</div>
    <div class="sport-item__image" v-bind:class="sport.code"></div>

    <select class="game-level-select custom-select" :name="selectName(this.sport.code)" v-model="picked_category_model" >
      <option value="">{{ I18n.t('game.choose_level') }}</option>
      <option v-for="(value, category) in sport_categories" v-bind:value="value">
        {{ category }}
      </option>
    </select>

    <input v-bind:name="selectName('_destroy')" value="1" v-if="pickedInitially && !picked_category_model" type="hidden">

    <div class="sport-schedule-form" v-bind:class="{ skiped: skipUserSportSchedulesBlock }">
      <div class="sport-schedule-form__title" style="font-size: 14px; margin-top: 12px;">{{ I18n.t("common.choose_preferable_days") }}</div>
      <user-sport-schedules-form-list v-on:change-sport-schedules-validity="validSportScheduleExist = $event" v-bind:sport_id="sport.id" v-bind:sport_schedules="local_sport_schedules"/>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      sport: Object,
      pickedCategory: String,
      sport_schedules: Array,
    },
    data: function() {
      const categories = {}
      categories[`A: ${I18n.t("sport_events.short_levels.a")}`] = "A"
      categories[`B: ${I18n.t("sport_events.short_levels.b")}`] = "B"
      categories[`C: ${I18n.t("sport_events.short_levels.c")}`] = "C"
      categories[`D: ${I18n.t("sport_events.short_levels.d")}`] = "D"

      return {
        sport_categories: categories,
        picked_category_model: this.pickedCategory,
        pickedInitially: this.pickedCategory,
        validSportScheduleExist: false,
        infoText: I18n.t("game.info_text"),
        local_sport_schedules: this.sport_schedules,
      }
    },
    computed: {
      isSelected: function() {
        if (this.picked_category_model && this.validSportScheduleExist)
          return true
        return false
      },
      selectedClass: function() {
        if (this.picked_category_model && (this.local_sport_schedules.length == 0 || this.validSportScheduleExist))
          return "selected"
      },
      skipUserSportSchedulesBlock: function() {
        return !this.picked_category_model
      },
    },
    methods: {
      selectName: function(name) {
        return `user[sports][${name}]`
      }
    }
  }
</script>

<style scoped>
  .skiped {
    display: none;
  }

  .sport-item {
    width: 100%;
    text-align: center;
    color: #524e4e;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    margin: 20px 20px;
    padding: 30px;
    background-color: #f4f4f3;
    max-width: 45%;
    min-width: 45%;

    @media only screen and (max-width: 600px) {
      max-width: 100%;
      padding: 20px;
      margin: 20px 30px;
    }
  }

  .selected {
    border-color: #6460ac;
    background-color: #f9f8fb;
  }

  .sport-item__name {
    text-align: left;
    font-size: 18px;
    font-weight: 500;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .game-level-select {
    margin-top: 24px;
  }

  .sport-item__image {
    height: 80px;
    width: 80px;
    margin: auto;
    margin-top: 24px;
  }

  .badminton {
    background: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .squash {
    background: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 80px 80px;
  }

  .sport-schedule-form {
    margin-top: 24px;
  }

  .sport-schedule-form__title {
    margin-bottom: 20px;
  }
</style>
