<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_plus">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span class="notification-item-img">
          <a :href="this.notification.data.profile_url">
            <img :src="this.notification.data.profile_image" v-if="!!this.notification.data.profile_image">
            <span v-else class="nickname">@{{ this.notification.data.profile_nickname }}:</span>
          </a>
        </span>
        <span class="notification-item-img-msg">
          {{ I18n.t("sport_events.notifications.sport_event_request_from_participant_created") }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
  }
</script>

<style scoped>
  .status {
    padding: 2px;
  }
</style>
