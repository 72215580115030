<template>
  <div class="user-partners">
    <a class="back-link" :href="`/users/${this.user_nickname}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq != 'sm'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div class="page-title" v-if="$mq != 'sm'" style="margin-top: 24px; margin-bottom: 24px;">
      {{ I18n.t("users.partners.title", { nickname: this.user_nickname }) }}
    </div>

    <div class="participants">
      <div class="participants__accepted">
        <div class="participant" v-for="participant in this.partners">
          <div class="participant__info">
            <div class="participant__avatar">
              <img :src="avatarPath(participant)">
            </div>
            <div class="participant__profile-link">
              <a :href="`/users/${participant.id}`">
                @{{ participant.nickname }}
              </a>
            </div>
          </div>
          <div style="position: relative;">
            <div class="common-sport-events-count" data-toggle="tooltip" :title="I18n.t('users.partners.common_events')">
              {{ partners_count[participant.id] }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      creator: Object,
      user_nickname: String,
      partners: Array,
      partners_count: Object
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      logoName(rank) {
        return `${rank}-medal`
      },
      avatarPath(user) {
        return showAvatar(user, 50)
      },
      fullName(user) {
        if (!user.name && !user.surname) {
          return
        }

        return `${this.user.name ? this.user.name : ''} ${this.user.surname ? this.user.surname : ''}`;
      },
    },
    computed: {
    }
  }
</script>

<style scoped>
  .user-partners {
    padding: 0px 15px;
    padding-top: 45px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .common-sport-events-count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    width: 30px;
    height: 30px;
  }

  .participants {
    text-align: center;

    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }

  .participants__accepted {
  }

  .participation__requests {
    margin: 40px 0px;
  }

  .participant {
    display: flex;
    margin: 10px 0px;
    justify-content: space-between;
    padding: 5px 0px;
    align-items: center;

    @media only screen and (min-width: 600px) {
      border-bottom: 1px solid #dcdbdb;
    }
  }

  .participant__avatar img {
    border-radius: 30px;
    width: 30px;
    height: 30px;
  }

  .participant__info {
    display: flex;
    align-items: center;
  }

  .participant__actions button {
    font-size: 13px !important;

    @media only screen and (max-width: 600px) {
      font-size: 12px !important;
    }
  }

  .participants--title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .participant__actions svg {
    height: 25px;
    width: 25px;
  }

  .participant__actions__accept {
    color: green !important;
  }

  .participant__profile-link {
    margin-left: 5px;
  }

  .participant a {
    text-decoration: none;
  }

  .beginner-medal {
  }

  .bronze-medal {
    background: url("../../images/bronze_medal.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 35px;
  }

  .silver-medal {
    background: url("../../images/silver_medal.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 35px;
  }

  .gold-medal {
    background: url("../../images/gold_medal.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 35px;
  }
</style>
