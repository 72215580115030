<template>
  <div class="event-review">
    <div class="title" :class="titleWrapCss()">{{ I18n.t("sport_events.review.title") }}</div>
    <div class="event-review-wrap" v-if="!this.responseCompleted">
      <div class="image-wrap" v-if="!isCreator">
        <image-rating :src="require('images/logo.png')" v-model="rating" :show-rating="false" :spacing="5" :item-size="40"></image-rating>
      </div>
      <div class="textarea-wrap" :class="textAreaWrapCss()">
        <textarea class="form-control" id="review-textarea" v-model="message" :placeholder="I18n.t('sport_events.review.placeholder')" rows="5" maxlength="50"></textarea>

        <emoji-picker @emoji="insert">
          <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
            </svg>
          </div>
          <div class="emoji-picker" slot="emoji-picker" slot-scope="{ emojis, insert, display }">
            <div>
              <div>
                <div v-for="(emojiGroup, category) in emojis" :key="category">
                  <h5>{{ category }}</h5>
                  <div>
                    <span
                      v-for="(emoji, emojiName) in emojiGroup"
                      :key="emojiName"
                      @click="insert(emoji)"
                      :title="emojiName"
                    >{{ emoji }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </emoji-picker>
      </div>
      <div class="submit-button-wrap" :class="submitWrapCss()">
        <a href="" class="btn cta-btn purple" v-on:click.prevent.stop="sendResponse()">
          {{ I18n.t("common.buttons.save") }}
        </a>
      </div>
    </div>

    <div class="event-review-success-info" v-else>
      <div class="event-review-success-info__image">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <ellipse style="fill:#32BEA6;" cx="256" cy="256" rx="256" ry="255.832"/>
        <polygon style="fill:#FFFFFF;" points="235.472,392.08 114.432,297.784 148.848,253.616 223.176,311.52 345.848,134.504
          391.88,166.392 "/>
        </svg>
      </div>

      <div class="event-review-success-info__message">
        {{ I18n.t('sport_events.review.success_message') }}
      </div>

      <div class="event-review-success-info__button-wrap">
        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.close") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      sportEventId: Number,
      isCreator: Boolean
    },
    data: function() {
      return {
        responseCompleted: false,
        rating: null,
        message: ""
      }
    },
    methods: {
      sendResponse() {
        if (!this.rating && !this.message)
          return

        axios
            .post("/api/v1/user_sport_event_reviews/",
              {
                user_sport_event_review: {
                  sport_event_id: this.sportEventId,
                  result: this.rating,
                  comment: this.message
                }
              }
            )
            .then(response => {
              this.responseCompleted = true
              this.$eventHub.$emit("change-sport-event", response.data["sport_event"])
              this.$emit("close")
            })
            .catch(error => {
              console.log(error)
            })
      },
      insert(emoji) {
        this.message += ` ${emoji} `
        document.getElementById("review-textarea").innerText += ` ${emoji} `
        $("#review-textarea").trigger("click")
      },
      titleWrapCss() {
        if (!this.isCreator)
          return ''

        return "wrap-for-creator-title"
      },
      textAreaWrapCss() {
        if (!this.isCreator)
          return ''

        return "wrap-for-creator-textarea"
      },
      submitWrapCss() {
        if (!this.isCreator)
          return ''

        return "wrap-for-creator"
      }
    }
  }
</script>

<style scoped>
  .event-review {
    height: 100%;
  }

  .wrap-for-creator-title {
    margin-top: 20px !important;
  }

  .wrap-for-creator-textarea {
    margin-top: 50px !important;
  }

  .wrap-for-creator {
    margin-top: 30px !important;
  }

  .event-review-wrap {
    height: 80%;
    width: 229px;
    margin: 0px auto;
    margin-top: 20px;
  }

  .event-review-success-info__message {
    font-size: 14px;
  }

  .image-wrap {
    height: 60px;
    margin-bottom: 15px;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 450;
    padding-top: 15px;
  }

  .toggle-wrap {
    margin-top: 15px;
  }

  .textarea-wrap {
    position: relative;
  }

  .submit-button-wrap {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  #review-textarea {
    font-size: 16px !important;
  }

  .event-review-success-info {
    text-align: center;
  }

  .event-review-success-info__image {
    margin: 20px;
  }

  .event-review-success-info svg {
    width: 80px;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }

  .event-review-success-info__button-wrap {
    margin-top: 40px;
  }

  /* emoji-picker */
  .emoji-invoker {
    position: absolute;
    bottom: 0.4rem;
    right: 5px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }

  .emoji-invoker:hover {
    transform: scale(1.1);
  }

  .emoji-invoker > svg {
    fill: #000;
  }

  .emoji-picker {
    position: absolute;
    bottom: 50px;
    right: 1px;
    z-index: 10;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 16rem;
    height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;

    @media only screen and (max-width: 600px) {
      width: 14rem;
      bottom: 37px;
    }
  }

  .emoji-picker__search {
    display: flex;
  }

  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
  }

  .emoji-picker h5 {
    margin: 8px 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
  }

  .emoji-picker span {
    font-size: 22px;
    margin-right: 8px;

    @media only screen and (max-width: 600px) {
      font-size: 25px;
    }
  }

  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }

  .emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
  }

  .emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
  }

  /* toogle input */
  .toggle{
    --uiToggleSize: var(--toggleSize, 30px);
    --uiToggleIndent: var(--toggleIndent, .4em);
    --uiToggleBorderWidth: var(--toggleBorderWidth, 2px);
    --uiToggleColor: var(--toggleColor, #000);
    --uiToggleDisabledColor: var(--toggleDisabledColor, #868e96);
    --uiToggleBgColor: var(--toggleBgColor, #fff);
    --uiToggleArrowWidth: var(--toggleArrowWidth, 2px);
    --uiToggleArrowColor: var(--toggleArrowColor, #fff);

    display: inline-block;
    position: relative;
  }

  .toggle__input{
    position: absolute;
    left: -99999px;
  }

  .toggle__label{
    display: inline-flex;
    cursor: pointer;
    min-height: var(--uiToggleSize);
    padding-left: calc(var(--uiToggleSize) + var(--uiToggleIndent));
  }

  .toggle__label:before, .toggle__label:after{
    content: "";
    box-sizing: border-box;
    width: 1em;
    height: 1em;
    font-size: var(--uiToggleSize);

    position: absolute;
    left: 0;
    top: 0;
  }

  .toggle__label:before{
    border: var(--uiToggleBorderWidth) solid var(--uiToggleColor);
    z-index: 2;
  }

  .toggle__input:disabled ~ .toggle__label:before{
    border-color: var(--uiToggleDisabledColor);
  }

  .toggle__input:focus ~ .toggle__label:before{
    box-shadow: 0 0 0 2px var(--uiToggleBgColor), 0 0 0px 4px var(--uiToggleColor);
  }

  .toggle__input:not(:disabled):checked:focus ~ .toggle__label:after{
    box-shadow: 0 0 0 2px var(--uiToggleBgColor), 0 0 0px 4px var(--uiToggleColor);
  }

  .toggle__input:not(:disabled) ~ .toggle__label:after{
    background-color: var(--uiToggleColor);
    opacity: 0;
  }

  .toggle__input:not(:disabled):checked ~ .toggle__label:after{
    opacity: 1;
  }

  .toggle__text{
    margin-top: auto;
    margin-bottom: auto;
  }

  /*
  The arrow size and position depends from sizes of square because I needed an arrow correct positioning from the top left corner of the element toggle
  */

  .toggle__text:before{
    content: "";
    box-sizing: border-box;
    width: 0;
    height: 0;
    font-size: var(--uiToggleSize);

    border-left-width: 0;
    border-bottom-width: 0;
    border-left-style: solid;
    border-bottom-style: solid;
    border-color: var(--uiToggleArrowColor);

    position: absolute;
    top: .5428em;
    left: .2em;
    z-index: 3;

    transform-origin: left top;
    transform: rotate(-40deg) skew(10deg);
  }

  .toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text:before{
    width: .5em;
    height: .25em;
    border-left-width: var(--uiToggleArrowWidth);
    border-bottom-width: var(--uiToggleArrowWidth);
    will-change: width, height;
    transition: width .1s ease-out .2s, height .2s ease-out;
  }

  /*
  =====
  LEVEL 2. PRESENTATION STYLES
  =====
  */

  /*
  The demo skin
  */

  .toggle__label:before, .toggle__label:after{
    border-radius: 2px;
  }

  /*
  The animation of switching states
  */

  .toggle__input:not(:disabled) ~ .toggle__label:before,
  .toggle__input:not(:disabled) ~ .toggle__label:after{
    opacity: 1;
    transform-origin: center center;
    will-change: transform;
    transition: transform .2s ease-out;
  }

  .toggle__input:not(:disabled) ~ .toggle__label:before{
    transform: rotateY(0deg);
    transition-delay: .2s;
  }

  .toggle__input:not(:disabled) ~ .toggle__label:after{
    transform: rotateY(90deg);
  }

  .toggle__input:not(:disabled):checked ~ .toggle__label:before{
    transform: rotateY(-90deg);
    transition-delay: 0s;
  }

  .toggle__input:not(:disabled):checked ~ .toggle__label:after{
    transform: rotateY(0deg);
    transition-delay: .2s;
  }

  .toggle__text:before{
    opacity: 0;
  }

  .toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text:before{
    opacity: 1;
    transition: opacity .1s ease-out .3s, width .1s ease-out .5s, height .2s ease-out .3s;
  }

  /*
  =====
  LEVEL 3. SETTINGS
  =====
  */

  .toggle{
    --toggleColor: #690e90;
    --toggleBgColor: #9b59b6;
    --toggleSize: 50px;
  }
</style>
