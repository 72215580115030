<template>
  <a :href="this.notification.data.sport_event_url" :class="notificationLinkClass()">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span v-html="renderMessage()"></span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
      renderMessage() {
        return I18n.t(
          "sport_events.notifications.user_new_rank",
          {
            new_rank: I18n.t(`common.ranks.${this.notification.data.new_rank}`)
          }
        )
      },
      notificationLinkClass() {
        return `notification-item-wrap notification-item-new-rank-${this.notification.data.new_rank}`
      }
    }
  }
</script>

<style scoped>
</style>
