<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_time">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item-message">
      <span>
        {{ I18n.t("sport_events.notifications.sport_event_place_changed", { old_place_name: this.notification.data.old_place_name, new_place_name: this.notification.data.new_place_name }) }}
      </span>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
