<template>
  <div class="sport-event-place-images">
    <a class="back-link" :href="`/sport_events/${sport_event_id}`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq != 'sm'" style="margin-bottom: 24px;">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div v-if="telephone" style="margin-bottom: 36px;">
      <div class="title">{{ I18n.t('sport_event.place.titles.telephone') }}</div>
      <div style="margin-top: 12px;">
        <a :href="`tel: ${telephone}`" class="light-link" style="font-size: 14px;">{{ telephone }}</a>
      </div>
    </div>

    <div v-if="website" style="margin-bottom: 36px;">
      <div class="title">{{ I18n.t('sport_event.place.titles.website') }}</div>

      <div style="margin-top: 12px;">
        <a :href="website" class="light-link" style="font-size: 14px;">{{ website }}</a>
      </div>
    </div>

    <div class="image" v-if="images.length > 0">
      <div class="title">{{ I18n.t('sport_event.place.titles.images') }}</div>

      <div v-for="image in images" class="images-wrap">
        <img :src="image">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      images: Array,
      telephone: String,
      website: String,
      sport_event_id: Number,
    },
    data() {
      return {
        participantInActionId: null,
        reactedParticipants: [],
        currentProcessedReactions: {},
        sportEvent: this.sport_event,
      }
    }
  }
</script>

<style scoped>
  .sport-event-place-images {
    padding: 0px 15px;
    padding-top: 75px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .title {
    font-size: 15px;
    font-weight: 500;
  }

  .images-wrap img {
    max-width: 300px;
    max-height: 300px;
  }
</style>
