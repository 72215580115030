<template>
  <div class="user-stats">
    <div class="user-stats-title">{{ I18n.t("sport_event_stats.title") }}</div>
    <div class="sport-event-participation-stats">
      <div class="sport-event-participation-stat-container">
        <div class="sport-event-stat-value">
          {{ successful_events_as_creator }}
        </div>
        <div class="sport-event-stat-text">
          {{ I18n.t("users.profile.stats.successful_events_as_creator") }}
        </div>
      </div>
      <div class="sport-event-participation-stat-container">
        <div class="sport-event-stat-value">
          {{ successful_events_as_participant}}
        </div>
        <div class="sport-event-stat-text">
          {{ I18n.t("users.profile.stats.successful_events_as_participant") }}
        </div>
      </div>
    </div>

    <div class="count-stats">
      <div class="user-stats-title">{{ I18n.t("sport_event_stats.count_title") }}</div>

      <div class="current-month count-stats-block" v-if="count_stats.current_month.avg_week > 0">
        <div class="title">{{ I18n.t("sport_event_stats.count_current_month") }}</div>

        <div v-for="(sportCount, sport) in count_stats.current_month.count" class="sport">
          {{ I18n.t(`sports.${sport}`) }}: {{ sportCount }}
        </div>

        <div class="avg-week">{{ I18n.t("sport_event_stats.avg_in_week") }} {{ count_stats.current_month.avg_week }}</div>
      </div>

      <div class="current-month count-stats-block" v-else>
        <div class="title">{{ I18n.t("sport_event_stats.count_current_month") }}: 0</div>
      </div>

      <div class="prev-month count-stats-block" v-if="count_stats.previous_month.avg_week > 0">
        <div class="title">{{ I18n.t("sport_event_stats.count_last_month") }}</div>

        <div v-for="(sportCount, sport) in count_stats.previous_month.count" class="sport">
          {{ I18n.t(`sports.${sport}`) }}: {{ sportCount }}
        </div>

        <div class="avg-week">{{ I18n.t("sport_event_stats.avg_in_week") }} {{ count_stats.previous_month.avg_week }}</div>
      </div>

      <div class="prev-month count-stats-block" v-else>
        <div class="title">{{ I18n.t("sport_event_stats.count_last_month") }}: 0</div>
      </div>

      <div v-for="month in monthes">
        <div class="count-stats-block" v-if="count_stats[month].avg_week > 0">
          <div class="title">{{ I18n.t(`date.month_names_keys.${month}`) }}</div>

          <div v-for="(sportCount, sport) in count_stats[month].count" class="sport">
            {{ I18n.t(`sports.${sport}`) }}: {{ sportCount }}
          </div>

          <div class="avg-week">{{ I18n.t("sport_event_stats.avg_in_week") }} {{ count_stats[month].avg_week }}</div>
        </div>

        <div class="count-stats-block" v-else>
          <div class="title">{{ I18n.t("sport_event_stats.count_last_month") }}: 0</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      successful_events_as_creator: Number,
      successful_events_as_participant: Number,
      count_stats: Object
    },
    data: function() {
      return {
      }
    },
    computed: {
      monthes() {
        return Object.keys(this.count_stats).filter((i) => { return (i != "current_month" && i != "previous_month") })
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .user-stats {
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  .user-stats-title {
    font-size: 15px;
    font-weight: bold;
    color: #5a5555;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    padding: 10px 0px;
  }

  .user-stats .title {
    font-weight: 500;
    font-size: 14px;
    color: #161517;
    margin-bottom: 6px;
  }

  .user-stats h4 {
    margin-bottom: 25px;
  }

  .user-stats .number {
    font-size: 32px;
    font-weight: 300;
    color: #000;
    margin-right: 5px;
  }

  .stats-wrap {
    margin-bottom: 15px;
  }

  .number-with-stats {
    display: flex;
    align-items: center;
  }

  .user-stats h3 {
    text-align: center;
  }

  .arrow-up {
    color: green;
  }

  .arrow-down {
    color: #c53535;
  }

  .avg-week {

  }

  .count-stats-block {
    margin-bottom: 15px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 10px;
  }

  .count-stats .sport {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
  }

  .sport-event-participation-stats {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 250px;
  }

  .sport-event-participation-stat-container {
    text-align: center;
  }

  .sport-event-stat-value {
    color: #000;
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 8px;

    @media only screen and (max-width: 600px) {
      font-size: 21px;
    }
  }

  .sport-event-stat-text {
    color: #7f7f7f;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
</style>
