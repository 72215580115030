<template>
  <div class="user-events-list">
    <div class="sport-event-stats-link" v-if="this.current_user.id == this.user.id">
      <a class="navigation-link-sm" href="/users/sport_event_stats">
        <font-awesome-icon icon="chart-line" size="lg" />
        {{ I18n.t("sport_events.show.stats_link") }}
        <font-awesome-icon icon="arrow-up" size="md" class="arrow-up" v-if="this.stats_direction && this.stats_direction == 'up'"/>
        <font-awesome-icon icon="arrow-down" size="md" class="arrow-down" v-if="this.stats_direction && this.stats_direction == 'down'"/>
      </a>
    </div>

    <div class="sport-event-participations">
      <div class="sport-event-nav" v-if="this.future_sport_events.length > 0 || this.creator_request_events.length > 0">
        <a class="sport-event-nav-item" :class="activeItemClassName('future')" v-if="this.future_sport_events.length > 0">
          <div class="sport-event-nav-item-title">
            {{ I18n.t("users.profile.future_events") }}
          </div>
          <div class="sport-event-nav-item-value" :class="activeItemValueClassName('future')" v-on:click.prevent.stop="changeActiveTab('future')">
            {{ this.future_sport_events.length }}
          </div>
        </a>

        <a class="sport-event-nav-item" :class="activeItemClassName('creator_requests')" v-if="this.creator_request_events.length > 0">
          <div class="sport-event-nav-item-title">
            {{ I18n.t("users.profile.creator_requests_events") }}
          </div>
          <div class="sport-event-nav-item-value" :class="activeItemValueClassName('creator_requests')" v-on:click.prevent.stop="changeActiveTab('creator_requests')">
            {{ this.creator_request_events.length }}
          </div>
        </a>

        <a class="sport-event-nav-item" :class="activeItemClassName('past')" v-on:click.prevent.stop="changeActiveTab('past')">
          <div class="sport-event-nav-item-title">
            {{ I18n.t("users.profile.past_events") }}
          </div>
          <div class="sport-event-nav-item-value" :class="activeItemValueClassName('past')">
            {{ this.user.taken_part_events_count }}
          </div>
        </a>
      </div>

      <div class="empty-list" v-if="(this.future_sport_events.length == 0 && this.creator_request_events.length == 0 && this.user.taken_part_events_count == 0) && this.current_user.id == this.user.id">
        <div class="empty-list-text">
          {{ I18n.t("sport_events.index.user_empty_list") }}
        </div>
        <div>
          <a href="/sport_events/new" class="btn cta-btn purple">
            + {{ I18n.t("sport_events.index.new_button") }}
          </a>
        </div>
      </div>
    </div>

    <div class="sport-events-list" v-if="this.activeParticipationTab == 'future'">
      <event-list-item v-for="sport_event in this.future_sport_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true"/>
    </div>

    <div class="sport-events-list" v-if="this.activeParticipationTab == 'creator_requests'">
      <event-list-item v-for="sport_event in this.creator_request_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true"/>
    </div>

    <div v-if="this.activeParticipationTab == 'past'">
      <div class="month-list" v-if="last_week_past_events.length > 0">
        <div class="month-header">{{ I18n.t("datetime.last_week_in_words") }}</div>
        <div class="sport-events-list">
          <event-list-item v-for="sport_event in last_week_past_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true"/>
        </div>
      </div>

      <div class="month-list"  v-for="(sport_events, month) in past_sport_events">
        <div class="month-header">{{ I18n.t(`date.month_names_keys.${month}`) }}</div>
        <div class="sport-events-list">
          <event-list-item v-for="sport_event in sport_events" :key="sport_event.id" :sport_event="sport_event" :show_date="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      user: Object,
      current_user: Object,
      future_sport_events: Array,
      past_sport_events: Object,
      last_week_past_events: Array,
      creator_request_events: Array,
      stats_direction: String
    },
    data: function() {
      return {
        activeParticipationTab: this.future_sport_events.length > 0 ? "future" : (this.creator_request_events.length > 0 ? "creator_requests" : "past")
      }
    },
    computed: {
    },
    methods: {
      activeItemClassName(tabName) {
        if (tabName != this.activeParticipationTab) {
          return ''
        }

        return 'sport-event-nav-item--active'
      },
      activeItemValueClassName(tabName) {
        if (tabName != this.activeParticipationTab) {
          return ''
        }
        return 'sport-event-nav-item-value--active'
      },
      changeActiveTab(tabName) {
        this.activeParticipationTab = tabName
      },
    }
  }
</script>

<style scoped>
  .user-events-list {
    padding-top: 65px;
  }

  .sport-events-list {
    text-align: center;

    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .sport-event-stats-link {
    margin-top: 25px;
    margin-left: 12px;
  }

  .sport-event-participations {
    background-color: #fff;
    margin: 10px 0px 25px 0px;
    margin-bottom: 30px;

    @media only screen and (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .sport-event-nav {
    height: 60px;
    display: flex;
    justify-content: center;
  }

  .sport-event-nav-item {
    cursor: pointer;
    color: #657786;
    height: 60px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 0 solid;
    display: block;
    line-height: 1;
    padding: 14px 15px 7px;
    transition: all .15s ease-in-out;
  }

  .sport-event-nav-item--active {
    border-bottom-width: 2px;
  }

  .sport-event-nav-item-title {
    color: #657786;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: .02em;
    transition: color .15s ease-in-out;
  }

  .sport-event-nav-item-value {
    color: #657786;
    font-size: 20px;
    font-weight: bold;
    padding-top: 3px;
    transition: color .15s ease-in-out;
  }

  .month-header {
    text-align: center;
    margin: 30px 0px;
    font-weight: 450;
  }

  .sport-event-nav-item-value--active {
    color: black !important;
  }

  .empty-list {
    text-align: center;
    font-size: 14px;
  }

  .empty-list-text {
    margin-bottom: 24px;
    margin-top: 36px;
    font-size: 15px;
  }

  .cta-btn {
    display: inline-block;
    font-size: 15px;
    min-width: 100px;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .badminton {
    background-image: url("../../images/badminton.png");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .squash {
    background-image: url("../../images/squash-event.svg");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .football {
    background: url("../../images/football.png");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .basketball {
    background: url("../../images/basketball.png");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .tennis {
    background: url("../../images/tennis.png");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 50px 50px;
  }

  .arrow-up {
    color: green;
  }

  .arrow-down {
    color: #c53535;
  }
</style>
