import TurbolinksAdapter from "vue-turbolinks";
import ActionCableVue from "actioncable-vue";
import Vue from "vue/dist/vue.esm"
import App from "../app.vue"
// import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker"
import "vue-airbnb-style-datepicker/dist/styles.css"
import { EmojiPickerPlugin } from "vue-emoji-picker"
// import VModal from "vue-js-modal"
import VModal from "../components/forks/vue-js-modal"
import linkify from "vue-linkify"
import Raters from "vue-rate-it"
import DropdownDatepicker from "vue-dropdown-datepicker";

import ScrollLoader from "vue-scroll-loader"
Vue.use(ScrollLoader)

import "pretty-checkbox/src/pretty-checkbox.scss"

Vue.directive("linkified", linkify)
Vue.use(TurbolinksAdapter)
Vue.use(EmojiPickerPlugin)

import axios from "axios"
import VueMq from "vue-mq"

import VueFormWizard from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
Vue.use(VueFormWizard)

import VueFormGenerator from "vue-form-generator"
import "vue-form-generator/dist/vfg.css";

import AvatarCropper from "../components/forks/avatar_cropper/show.vue"
Vue.component("avatar-cropper", AvatarCropper)

import StepProgress from "../components/forks/vue_step_progress/show.vue"
Vue.component("step-progress", StepProgress)

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faQuestionCircle,
  faEnvelope,
  faCalendarAlt,
  faCalendarPlus,
  faCog,
  faPlus,
  faMapMarkerAlt,
  faArrowLeft,
  faComment,
  faCommentDots,
  faPaperPlane,
  faSignOutAlt,
  faInfoCircle,
  faUserAlt,
  faUsers,
  faComments,
  faMessage,
  faCheck,
  faPlusCircle,
  faCogs,
  faExclamationCircle,
  faArrowUp,
  faArrowDown,
  faChartLine,
  faRedo,
  faSearch,
  faLockOpen,
  faLock,
  faUnlock,
  faUserClock,
  faClock,
  faCheckSquare,
  faEdit,
  faUserPlus,
  faLightbulb,
  faArrowCircleRight,
  faCheckCircle,
  faChevronRight,
  faTimesCircle,
  faFrown,
  faThumbsUp,
  faLink,
  faUser,
  faHome,
  faMapPin
} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

const Slideout = require("slideout")

library.add(
  faQuestionCircle, faEnvelope, faCalendarAlt, faCog, faPlus, faRedo,
  faMapMarkerAlt, faArrowLeft, faComment, faPaperPlane,
  faCalendarPlus, faSignOutAlt, faInfoCircle, faUserAlt,
  faComments, faCheck, faPlusCircle, faCogs, faUsers, faExclamationCircle,
  faArrowUp, faArrowDown, faChartLine, faCommentDots, faSearch, faLockOpen,
  faLock, faUnlock, faUserClock, faCheckSquare, faEdit, faUserPlus, faLightbulb,
  faArrowCircleRight, faCheckCircle, faFrown, faLink, faChevronRight, faClock,
  faTimesCircle, faUser, faHome, faThumbsUp,faMapPin
)

import Header from "../components/header/show.vue"
import HeaderMobile from "../components/header/show_mobile.vue"
import HeaderDesktop from "../components/header/show_desktop.vue"
import SideMenu from "../components/header/side_menu.vue"

import Notifications from "../components/notifications/index.vue"
import SportEventTimeChanged from "../components/notifications/by_event/sport_event_time_changed.vue"
import SportEventPlaceChanged from "../components/notifications/by_event/sport_event_place_changed.vue"
import SportEventStatusChanged from "../components/notifications/by_event/sport_event_status_changed.vue"
import SportEventRequestFromParticipantCreated from "../components/notifications/by_event/sport_event_request_from_participant_created.vue"
import SportEventRequestFromParticipantAccepted from "../components/notifications/by_event/sport_event_request_from_participant_accepted.vue"
import SportEventRejectedByParticipant from "../components/notifications/by_event/sport_event_rejected_by_participant.vue"
import SportEventRefusedByParticipant from "../components/notifications/by_event/sport_event_refused_by_participant.vue"
import SportEventRejectedByCreator from "../components/notifications/by_event/sport_event_rejected_by_creator.vue"
import SportEventRequestFromCreatorCreated from "../components/notifications/by_event/sport_event_request_from_creator_created.vue"
import SportEventRequestFromCreatorAccepted from "../components/notifications/by_event/sport_event_request_from_creator_accepted.vue"
import SportEventParticipantAccepted from "../components/notifications/by_event/sport_event_participant_accepted.vue"
import SportEventSubscriptionInvoked from "../components/notifications/by_event/sport_event_subscription_invoked.vue"
import SportEventPositiveReaction from "../components/notifications/by_event/sport_event_positive_reaction.vue"
import SportEventReviewCreated from "../components/notifications/by_event/sport_event_review_created.vue"
import SportEventBooked from "../components/notifications/by_event/sport_event_booked.vue"
import SportEventStartReminder from "../components/notifications/by_event/sport_event_start_reminder.vue"
import SportEventNotEnoughParticipants from "../components/notifications/by_event/sport_event_not_enough_participants.vue"
import NewChatMessage from "../components/notifications/by_event/new_chat_message.vue"
import NewMassChatMessage from "../components/notifications/by_event/new_mass_chat_message.vue"
import UserNewRankPercentage from "../components/notifications/by_event/user_new_rank_percentage.vue"
import UserNewRank from "../components/notifications/by_event/user_new_rank.vue"
import SportCompetitionCreated from "../components/notifications/by_event/sport_competition_created.vue"
import SportCompetitionChallengeAccepted from "../components/notifications/by_event/sport_competition_challenge_accepted.vue"
import SportCompetitionChallengeDeclined from "../components/notifications/by_event/sport_competition_challenge_declined.vue"
import SportCompetitionResultSubmitted from "../components/notifications/by_event/sport_competition_result_submitted.vue"
import SportCompetitionResultAccepted from "../components/notifications/by_event/sport_competition_result_accepted.vue"
import SportCompetitionResultDeclined from "../components/notifications/by_event/sport_competition_result_declined.vue"
import SportCompetitionCongratulateWinner from "../components/notifications/by_event/sport_competition_congratulate_winner.vue"
import SportCompetitionNotifyLoser from "../components/notifications/by_event/sport_competition_notify_loser.vue"

import SportsList from "../components/sports/index.vue"
import SportItem from "../components/sports/show.vue"
import UserSportScheduleFormItem from "../components/user_sport_schedules_form/show.vue"
import UserSportScheduleFormList from "../components/user_sport_schedules_form/index.vue"

import SportEventsList from "../components/sport_events/index.vue"
import SportEventListItem from "../components/sport_events/index_item.vue"
import SportEventMinimalListItem from "../components/sport_events/minimal_index_item.vue"
import SportEventItem from "../components/sport_events/show.vue"
import SportEventPartners from "../components/sport_events/partners.vue"
import SportEventReview from "../components/sport_events/review.vue"
import SportEventPlaceInfo from "../components/sport_events/place_info.vue"
import SportEventForm from "../components/sport_events/form.vue"
import SportEventEditForm from "../components/sport_events/edit.vue"
import SportEventStats from "../components/sport_events/stats.vue"
import UserSportEventsList from "../components/sport_events/user_index.vue"
import SportEventCompetitionNewForm from "../components/sport_competitions/new_form.vue"
import SportEventCompetitionList from "../components/sport_competitions/index.vue"
import SportEventCompetition from "../components/sport_competitions/show.vue"
import SportCompetitionResultSubmission from "../components/sport_competitions/result_submition.vue"

import UserProfile from "../components/users/show.vue"
import UsersList from "../components/users/index.vue"
import UserEditProfile from "../components/users/edit.vue"
import UserSportList from "../components/users/sports.vue"
import UserPartnersList from "../components/users/partners.vue"
import InviteUser from "../components/users/invite.vue"

import SportEventChat from "../components/sport_event_chat/show.vue"
import SportEventChatMessage from "../components/sport_event_chat_message/show.vue"

import FooterNav from "../components/footer/show.vue"

import TopTabs from "../components/tabs/top_tabs.vue"

import EmailConfirmationAlert from "../components/alerts/email_confirmation_alert.vue"

import FirstRegistrationStep from "../components/registration_steps/first_step.vue"
import SecondRegistrationStep from "../components/registration_steps/second_step.vue"
import ThirdRegistrationStep from "../components/registration_steps/third_step.vue"
import FourthRegistrationStep from "../components/registration_steps/fourth_step.vue"
import FifthRegistrationStep from "../components/registration_steps/fifth_step.vue"

Vue.component("image-rating", Raters.ImageRating)
Vue.component("dropdown-datepicker", DropdownDatepicker)

Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.config.productionTip = false

Vue.component("vue-form-generator", VueFormGenerator.component)

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: process.env.RAILS_ENV == "development" ? "ws://lvh.me:3000/cable" : "wss://spottyspo.com/cable"
})

Vue.use(VueMq, {
  breakpoints: {
    sm: 600,
    md: 1250,
    lg: Infinity,
  }
})

Vue.component("app", App)

Vue.component("header-nav", Header)
Vue.component("header-nav-mobile", HeaderMobile)
Vue.component("header-nav-desktop", HeaderDesktop)
Vue.component("side-menu", SideMenu)

Vue.component("notifications", Notifications)
Vue.component("sport-event-status-changed-notification", SportEventStatusChanged)
Vue.component("sport-event-time-changed-notification", SportEventTimeChanged)
Vue.component("sport-event-place-changed-notification", SportEventPlaceChanged)
Vue.component("sport-event-request-from-participant-created-notification", SportEventRequestFromParticipantCreated)
Vue.component("sport-event-request-from-participant-accepted-notification", SportEventRequestFromParticipantAccepted)
Vue.component("sport-event-rejected-by-participant-notification", SportEventRejectedByParticipant)
Vue.component("sport-event-refused-by-participant-notification", SportEventRefusedByParticipant)
Vue.component("sport-event-participant-rejected-by-creator-notification", SportEventRejectedByCreator)
Vue.component("sport-event-request-from-creator-created-notification", SportEventRequestFromCreatorCreated)
Vue.component("sport-event-request-from-creator-accepted-notification", SportEventRequestFromCreatorAccepted)
Vue.component("sport-event-participant-accepted-notification", SportEventParticipantAccepted)
Vue.component("sport-event-subscription-invoked-notification", SportEventSubscriptionInvoked)
Vue.component("sport-event-positive-reaction-notification", SportEventPositiveReaction)
Vue.component("sport-event-review-created-notification", SportEventReviewCreated)
Vue.component("sport-event-booked-notification", SportEventBooked)
Vue.component("sport-event-start-reminder-notification", SportEventStartReminder)
Vue.component("sport-event-not-enough-participants-notification", SportEventNotEnoughParticipants)
Vue.component("new-chat-message-notification", NewChatMessage)
Vue.component("new-mass-chat-message-notification", NewMassChatMessage)
Vue.component("user-new-rank-percentage-notification", UserNewRankPercentage)
Vue.component("user-new-rank-notification", UserNewRank)
Vue.component("sport-competition-created-notification", SportCompetitionCreated)
Vue.component("sport-competition-challenge-accepted-notification", SportCompetitionChallengeAccepted)
Vue.component("sport-competition-challenge-declined-notification", SportCompetitionChallengeDeclined)
Vue.component("sport-competition-result-submitted-notification", SportCompetitionResultSubmitted)
Vue.component("sport-competition-result-accepted-notification", SportCompetitionResultAccepted)
Vue.component("sport-competition-result-declined-notification", SportCompetitionResultDeclined)
Vue.component("sport-competition-congratulate-winner-notification", SportCompetitionCongratulateWinner)
Vue.component("sport-competition-notify-loser-notification", SportCompetitionNotifyLoser)

Vue.component("sports-list", SportsList)
Vue.component("sport-item", SportItem)
Vue.component("user-sport-schedules-form-list", UserSportScheduleFormList)
Vue.component("user-sport-schedules-form-item", UserSportScheduleFormItem)

Vue.component("events", SportEventsList)
Vue.component("user-events", UserSportEventsList)
Vue.component("event-list-item", SportEventListItem)
Vue.component("minimal-event-list-item", SportEventMinimalListItem)
Vue.component("event-partners", SportEventPartners)
Vue.component("event-review", SportEventReview)
Vue.component("event-place-info", SportEventPlaceInfo)
Vue.component("event-item", SportEventItem)
Vue.component("event-item-form", SportEventForm)
Vue.component("event-item-edit-form", SportEventEditForm)
Vue.component("event-stats", SportEventStats)

Vue.component("new-sport-competition", SportEventCompetitionNewForm)
Vue.component("sport-competition-list", SportEventCompetitionList)
Vue.component("sport-competition", SportEventCompetition)
Vue.component("sport-competition-result-submission", SportCompetitionResultSubmission)

Vue.component("user-profile", UserProfile)
Vue.component("users-list", UsersList)
Vue.component("user-edit-profile", UserEditProfile)
Vue.component("user-sport-list", UserSportList)
Vue.component("user-partners-list", UserPartnersList)
Vue.component("invite-user", InviteUser)

Vue.component("sport-event-chat", SportEventChat)
Vue.component("sport-event-chat-message", SportEventChatMessage)

Vue.component("first-registration-step", FirstRegistrationStep)
Vue.component("second-registration-step", SecondRegistrationStep)
Vue.component("third-registration-step", ThirdRegistrationStep)
Vue.component("fourth-registration-step", FourthRegistrationStep)
Vue.component("fifth-registration-step", FifthRegistrationStep)

Vue.component("top-tabs", TopTabs)
Vue.component("footer-nav", FooterNav)

Vue.component("email-confirmation-alert", EmailConfirmationAlert)

function loadFormGeneratorErrorMessages() {
  // load translation for validator error messages
  if (I18n.locale == "en") {
    return
  }

  const res = VueFormGenerator.validators.resources

  Object.keys(res).forEach((el) => {
    res[el] = I18n.t(`vue_form_generator.errors.${el}`)
  })
}

document.addEventListener("turbolinks:load", () => {
  // hack for turbolinks and slideout
  document.querySelector("html").className = ""
})

document.addEventListener("turbolinks:load", () => {
  Vue.prototype.CurrentUserId = $("body").data("user-id")
  axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  I18n.locale = $("body").data("locale")

  Vue.prototype.I18n = I18n

  Vue.prototype.$eventHub = new Vue();

  // see docs for available options
  // const datepickerOptions = {
  //   days: I18n.t("date.day_names"),
  //   daysShort: I18n.t("date.default_abbr_day_names"),
  //   monthNames: I18n.t("date.default_month_names"),
  // }

  // // make sure we can use it in our components
  // Vue.use(AirbnbStyleDatepicker, datepickerOptions)

  // we don't invoke Vue.use because its executed only onces and this not working with turbolinks
  // (it's not recreated components from install method)
  VModal.install(Vue, { dynamic: true })

  const app = new Vue({
    el: '[data-behavior="vue"]',
  })

  $('[data-toggle="tooltip"]').tooltip()

  loadFormGeneratorErrorMessages()

  if (document.getElementById('panel') && document.getElementById('menu')) {
    const slideout = new Slideout({
      'panel': document.getElementById('panel'),
      'menu': document.getElementById('menu'),
      'padding': 256,
      'duration': 300,
      'tolerance': 70,
      'easing': 'cubic-bezier(.32,2,.55,.27)'
    })

    if (document.querySelector('.toggle-button')) {
      // Toggle button
      document.querySelector('.toggle-button').addEventListener('click', function() {
        slideout.toggle()
      })
    }

    const fixed = document.querySelector('.fixed-header');

    slideout.on('translate', function(translated) {
      fixed.style.transform = 'translateX(' + translated + 'px)';
    });

    slideout.on('beforeopen', function () {
      fixed.style.transition = 'transform 300ms ease';
      fixed.style.transform = 'translateX(256px)';
    });

    slideout.on('beforeclose', function () {
      fixed.style.transition = 'transform 300ms ease';
      fixed.style.transform = 'translateX(0px)';
    });

    slideout.on('open', function () {
      fixed.style.transition = '';
    });

    slideout.on('close', function () {
      fixed.style.transition = '';
    });

    // document.querySelector('.menu').addEventListener('click', function(eve) {
    //   if (eve.target.nodeName === 'A') { slideout.close(); }
    // })
  }
})
