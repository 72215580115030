import { render, staticRenderFns } from "./sport_competition_result_submitted.vue?vue&type=template&id=2bb26fda&scoped=true&"
import script from "./sport_competition_result_submitted.vue?vue&type=script&lang=js&"
export * from "./sport_competition_result_submitted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb26fda",
  null
  
)

export default component.exports