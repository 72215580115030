<template>
  <div>
    <div class="user__profile">
      <top-tabs :tabs="this.tabs"/>

      <div class="edit-user-block">
        <div class="title-wrap">
          <div class="title">{{ I18n.t("users.edit_form.titles.address") }}</div>
        </div>

        <div v-if="this.userLocal.street_name" style="margin-top: 24px">
          <div v-if="this.userLocal.street_name" class="user-steet-name">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            {{ this.userLocal.street_name }}
          </div>
          <div class="user-current-city">{{ `${this.userLocal.current_geo}` }}</div>

          <div class="title-description">
            <div>
              <font-awesome-icon icon="exclamation-circle" size="lg" />
              {{ I18n.t("users.edit_form.descriptions.address") }}
            </div>
          </div>

          <div class="submit-button-wrap">
            <a class="btn cta-btn purple btn-sm" v-on:click.prevent="openNewUserAdressModal()">
              {{ I18n.t("users.edit_form.buttons.address.change") }}
            </a>
          </div>
        </div>

        <div v-else style="margin-top: 24px">
          <div class="user-current-city">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            {{ this.userLocal.current_geo }}
          </div>

          <div class="title-description">
            <div>
              <font-awesome-icon icon="exclamation-circle" size="lg" />
              {{ I18n.t("users.edit_form.descriptions.address") }}
            </div>
          </div>

          <div class="submit-button-wrap">
            <a class="btn cta-btn purple btn-sm" v-on:click.prevent="openNewUserAdressModal()">
              + {{ I18n.t("users.edit_form.buttons.address.add") }}
            </a>
          </div>
        </div>
      </div>

      <div class="edit-user-block">
        <div class="title-wrap">
          <div class="title">{{ I18n.t("users.edit_form.titles.avatar") }}</div>
        </div>

        <div class="avatar-wrap">
          <img :src="imgPath()" />

          <div class="submit-button-wrap">
            <a class="btn cta-btn purple btn-sm" id="pick-avatar">
              {{ I18n.t("users.edit_form.change_avatar_button") }}
            </a>
          </div>
          <avatar-cropper
            @uploaded="handleUploaded"
            trigger="#pick-avatar"
            requestMethod="PUT"
            :upload-url="`/api/v1/users/avatars/${this.userLocal.id}`"
            upload-form-name="avatar"
            :upload-headers="avatarUploadHeaders()" />
        </div>
      </div>

      <div class="edit-user-block">
        <div class="title-wrap">
          <div class="title">{{ I18n.t("users.edit_form.titles.about") }}</div>
        </div>

        <div style="margin-top: 24px;">
          <vue-form-generator :model="user"
                              :schema="this.formAboutMeSchema"
                              :options="this.formOptions"
                              ref="formSchema"
                              />
        </div>

        <div class="form-group">
          <label>{{ I18n.t("users.edit_form.labels.date_of_birth") }}</label>
          <dropdown-datepicker v-bind:on-change="onBirthChange" :default-date="this.userLocal.date_of_birth" :monthLongValues="I18n.t('date.default_month_names')" :initialDayMonthYearValues="I18n.t('date.daymonthyearinitial')" :dayLabel="I18n.t('date.daymonthyearlabels.day')" :monthLabel="I18n.t('date.daymonthyearlabels.month')" :yearLabel="I18n.t('date.daymonthyearlabels.year')" maxYear="2010" defaultDateFormat="'dd-mm-yyyy'" display-format="dmy" :daySuffixValues="I18n.t('date.daysuffixvalues')">
          </dropdown-datepicker>
        </div>

        <div class="submit-button-wrap">
          <a class="btn cta-btn purple btn-sm" @click="submitAction()">
            {{ I18n.t("forms.submit") }}
          </a>
        </div>
      </div>

      <div class="edit-user-block">
        <div class="title-wrap">
          <div class="title">{{ I18n.t("users.edit_form.titles.notifications") }}</div>
        </div>

        <div style="margin-top: 24px;" class="alert alert-success-notification alert-dismissible" v-if="this.user.telegram_enabled">
          {{ I18n.t("users.edit_form.notifications.telegram.title") }} <a target="_blank" href="https://t.me/SpottySpoBot">t.me/spottybot</a> {{ I18n.t("users.edit_form.notifications.telegram.enabled") }}
        </div>

        <div style="margin-top: 24px;" class="alert alert-warning alert-dismissible" v-else>
          {{ I18n.t("users.edit_form.notifications.telegram.title") }} <a target="_blank" href="https://t.me/SpottySpoBot">t.me/spottybot</a> {{ I18n.t("users.edit_form.notifications.telegram.disabled") }}
          <div style="margin-top: 12px;">
            {{ I18n.t("users.edit_form.notifications.telegram.disabled_text") }}
          </div>
        </div>

        <div style="margin-top: 24px;">
          <email-confirmation-alert :user="this.user"/>
        </div>

        <div style="margin-top: 24px;">
          <vue-form-generator :model="user"
                              :schema="this.formSchema"
                              :options="this.formOptions"
                              ref="formSchema"
                              />
        </div>

        <div class="form-checkbox form-group">
          <div class="pretty p-svg p-curve">
            <input type="checkbox" v-model="userLocal.email_notifications_enabled" />
            <div class="state p-success">
                <!-- svg path -->
                <svg class="svg svg-icon" viewBox="0 0 20 20">
                    <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                </svg>
                <label>{{ I18n.t("users.edit_form.labels.email_notification_enabled") }}</label>
            </div>
          </div>
        </div>

        <div class="form-checkbox form-group">
          <div class="pretty p-svg p-curve">
            <input type="checkbox" v-model="userLocal.webpush_notifications_enabled" />
            <div class="state p-success">
                <!-- svg path -->
                <svg class="svg svg-icon" viewBox="0 0 20 20">
                    <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                </svg>
                <label>{{ I18n.t("users.edit_form.labels.webpush_notifications_enabled") }}</label>
            </div>
          </div>
        </div>

        <div class="submit-button-wrap">
          <a class="btn cta-btn purple btn-sm" @click="submitAction()">
            {{ I18n.t("forms.submit") }}
          </a>
        </div>
      </div>

      <div class="edit-user-block">
        <div class="title-wrap">
          <div class="title">{{ I18n.t("users.edit_form.titles.locale") }}</div>
        </div>

        <div style="margin-top: 24px;">
          <vue-form-generator :model="user"
                              :schema="this.formLocaleSchema"
                              :options="this.formOptions"
                              ref="formSchema"
                              />
        </div>

        <div class="submit-button-wrap">
          <a class="btn cta-btn purple btn-sm" @click="submitAction()">
            {{ I18n.t("forms.submit") }}
          </a>
        </div>
      </div>

      <div class="edit-user-block">
        <div class="title-wrap">
          <div class="title">{{ I18n.t("users.edit_form.titles.social_links") }}</div>
        </div>

        <div style="margin-top: 24px;">
          <vue-form-generator :model="user"
                              :schema="this.formSocialMediaSchema"
                              :options="this.formOptions"
                              ref="formSchema"
                              />
        </div>

        <div class="submit-button-wrap">
          <a class="btn cta-btn purple btn-sm" @click="submitAction()">
            {{ I18n.t("forms.submit") }}
          </a>
        </div>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import { validators } from "vue-form-generator"
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"
  import UserNewPlace from "../../components/users/modals/user_place.vue"

  export default {
    props: {
      user: Object,
      map_coords: Object
    },
    data: function() {
      return {
        date_of_birth: this.user.date_of_birth,
        errors: false,
        userLocal: this.user,
        tabs: [
          { code: "edit_profile", href: "/users/edit_profile", selected: true },
          { code: "sport_settings", href: "/user_settings/sports" },
        ],
        formAboutMeSchema: {
          fields: [
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.name"),
              inputType: "string",
              model: "name",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.name"),
              },
            },
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.surname"),
              inputType: "string",
              model: "surname",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.surname"),
              },
            },
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.nickname"),
              inputType: "string",
              model: "nickname",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.nickname"),
              },
            },
            {
              type: "textArea",
              inputType: "string",
              label: I18n.t("users.edit_form.labels.comment"),
              model: "description",
              placeholder: I18n.t("users.edit_form.placeholders.comment"),
              required: false,
            }
          ]
        },
        formSchema: {
          fields: [
           {
              type: "input",
              label: I18n.t("users.edit_form.labels.email"),
              inputType: "string",
              model: "email",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.email"),
              },
            },
          ]
        },
        formLocaleSchema: {
          fields: [
           {
              type: "select",
              label: I18n.t("users.edit_form.labels.locale"),
              model: "locale",
              values: [{ name: "Русский", id: "ru" }, { name: "English", id: "en" }],
              styleClasses: "col-xs-2",
              required: true,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.locale"),
              },
            }
          ]
        },
        formSocialMediaSchema: {
          fields: [
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.instagram_link"),
              inputType: "string",
              model: "instagram_link",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.instagram_link"),
              },
            },
            {
              type: "input",
              label: I18n.t("users.edit_form.labels.telegram_link"),
              inputType: "string",
              model: "telegram_link",
              styleClasses: "col-xs-6",
              required: false,
              validator: validators.string,
              selectOptions: {
                noneSelectedText: I18n.t("users.edit_form.non_selected_text.telegram_link"),
              },
            },
          ]
        },
        formOptions: {
          validationErrorClass: "has-error",
          validationSuccessClass: "has-success",
          validateAfterChanged: true,
        },
      }
    },
    created() {
      this.$eventHub.$on("new-user-place", this.changeUserPlace)
    },
    mounted() {
      $(".date-dropdowns select").addClass("form-control-date")
    },
    methods: {
      avatarUploadHeaders() {
        return { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
      },
      changeUserPlace(emmittedCurrentUser) {
        this.userLocal = emmittedCurrentUser
      },
      onBirthChange(day, month, year){
        if(day != '' && month != '' && year != ''){
          this.userLocal.date_of_birth = year+'-'+month+'-'+day
        }
      },
      imgPath() {
        const size = 200

        return showAvatar(this.userLocal, size)
      },
      handleUploaded(resp) {
        this.userLocal = resp.user;
      },
      dateSelected(date) {
        this.date_of_birth = date
        const dateObj = new Date(date)
        this.userLocal.date_of_birth = date
      },
      openNewUserAdressModal() {
        this.$modal.show(UserNewPlace,
          { current_user: this.userLocal, default_map_coords: this.map_coords },
          {
            height: "auto",
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      datePlaceholder() {
        return I18n.t("events.new_form.placeholders.date")
      },
      formInfo() {
        return I18n.t("users.edit_form.form_info")
      },
      minDate() {
        const today = new Date()
        return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      },
      submitAction() {
        this.errors = false

        axios
          .put(`/api/v1/users/${this.userLocal.id}`, { user: this.userLocal })
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))
          })
          .catch(error => {
            console.log(error.response.data)
            showAlert(
              error.response.data,
              "alert"
            )
          })
      }
    }
  }
</script>

<style scoped>
  .user__profile {
    font-size: 14px;
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 60px;
      font-size: 13px;
    }
  }

  .user-current-city {
    font-weight: 600;
    margin-top: 6px;
    font-size: 13px;
  }

  .user-steet-name {
    font-size: 13px;
  }

  .title-wrap {
    font-weight: 600;
    color: #909499;
  }

  .edit-user-block {
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 18px;
    margin-top: 24px;
  }

  .edit-user-block svg {
    color: #a7a7a7;
  }

  .title-description, .errors-wrap {
    font-weight: 350;
    font-size: 13px;
    margin-top: 24px;
    font-style: italic;
  }

  #success-notification {
    color: green;
  }

  .alert-success-notification {
    border-color: #0c6135;
    background-color: #d4edda;
    font-weight: 450;
    color: #265a32;
  }

  .avatar {
    width: 200px;
    border-radius: 6px;
    margin: 20px auto;
  }

  .submit-button-wrap {
    margin-top: 24px;
  }

  .card-img-overlay {
    display: block;
    transition: all 0.5s;
  }

  .avatar-wrap {
    margin-top: 24px;
  }

  .avatar-wrap img {
    width: 200px;
    height: 200px;
    border-radius: 6px;
  }

  .card-img-overlay button {
    margin-top: 25%;

    @media only screen and (max-width: 600px) {
      margin-top: 60%;
    }
  }

  .card-body {
    position: relative;
    padding: 0;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
    color: #fff;
  }
</style>
