<template>
  <div class="edit-sport-event-participants-list">
    <div class="title">{{ I18n.t("sport_events.new_form.more_previous_participants_title") }}</div>

    <div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" style="border: none;" v-for="(participant, index) in this.participants">
          <div class="list-item-wrap">
            <div class="list-user-item-wrap">
              <img class="avatar" :src="imgPath(participant)" height="45" width="45">
              <span style="color: black; font-weight: 600;">@{{ participant.nickname.substring(0,12) }}</span>
            </div>
            <label class="switch ">
              <input type="checkbox" class="default" :value="participant.id" v-model="localSelectedParticipants">
              <span class="slider round"></span>
            </label>
          </div>
        </li>
      </ul>
    </div>

    <div style="margin-bottom: 24px; color: grey;">
      {{ I18n.t("sport_events.new_form.selected_participants_count", { selected_count: localSelectedParticipants.length, all_count: this.participants.length }) }}
    </div>

    <div style="display: flex;">
      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn btn-sm purple" v-on:click.prevent.stop="chooseParticipants()">
          {{ I18n.t("common.buttons.apply") }}
        </a>
      </div>

      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn btn-sm cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.cancel") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import showAvatar from "../../../utils/avatar"

  export default {
    props: {
      participants: Array,
      selectedParticipants: Array,
    },
    data: function() {
      return {
        localSelectedParticipants: this.selectedParticipants
      }
    },
    mounted() {
      $(".v--modal-box").css({ "overflow-y": "scroll" })
    },
    methods: {
      imgPath(partitipant) {
        return showAvatar(partitipant, 45)
      },
      chooseParticipants() {
        this.$eventHub.$emit("change-sport-event-form-participants", this.localSelectedParticipants)
        this.$emit("close")
      }
    }
  }
</script>

<style scoped>
  .edit-sport-event-participants-list {
    margin: 30px 30px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .list-group-item img {
    border-radius: 20px;
  }

  .list-group-item {
    padding: 0.75rem 0em;
  }

  .list-item-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    padding: 10px;
  }

  .list-user-item-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    height: 80px;
    width: 60px;
    margin-left: 12px;
  }

  .list-user-item-wrap span {
    font-size: 13px;
    margin-top: 4px;
  }

  .list-user-item-wrap img {
    margin-top: 3px;
  }

  /* switches */

  /* The switch - the box around the slider */

  .switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 30px;
    float:right;
  }

  /* Hide default HTML checkbox */
  .switch input {display:none;}

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input.default:checked + .slider {
    background-color: #6460ac;
  }
  input.primary:checked + .slider {
    background-color: #2196F3;
  }
  input.success:checked + .slider {
    background-color: #8bc34a;
  }
  input.info:checked + .slider {
    background-color: #3de0f5;
  }
  input.warning:checked + .slider {
    background-color: #FFC107;
  }
  input.danger:checked + .slider {
    background-color: #f44336;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    font-size: 13px;
  }
</style>
