<template>
  <div class="fifth-step">
    <div class="step-counters"><div class="step-counters">{{ I18n.t("registration.current_step", { current_step_number: 5 }) }}</div></div>

    <div class="success-notification-title">
      <div class="first-line">{{ I18n.t("registration.fifth_step.info_text") }}</div>
    </div>

    <div class="telegram-link" v-if="!this.telegram_enabled">
      <a target="_blank" href="https://t.me/SpottySpoBot">t.me/spottybot</a>
    </div>

    <div class="second-line" v-if="!this.telegram_enabled">
      {{ I18n.t("registration.fifth_step.description") }}<br>
    </div>

    <div class="success-telegram-message" v-if="this.telegram_enabled">
      <font-awesome-icon icon="check-circle" size="1x" />
      {{ I18n.t("registration.fifth_step.success_description") }}
    </div>

    <div class="buttons-wrap">
      <button class="btn cta-btn purple" v-on:click.prevent="updateStep(6)">
        {{ I18n.t("registration.fifth_step.buttons.finish") }}
      </button>
    </div>
  </div>
</template>

<script>
  import updateStepMixin from "../mixins/update_step"

  export default {
    props: {
      telegram_enabled: { type: Boolean, default: true }
    },
    data: function() {
      return {
      }
    },
    mixins: [updateStepMixin],
  }
</script>

<style scoped>
  .fifth-step {
    font-size: 14px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding: 0px 15px;
      padding-top: 65px;
    }
  }

  .success-notification-title {
    margin-bottom: 20px;
    margin-top: 24px;

    @media only screen and (min-width: 600px) {
      text-align: center;
    }
  }

  .success-notification-title .first-line {
    font-weight: 450;
    margin-bottom: 36px;
    font-size: 16px;

    @media only screen and (max-width: 600px) {
      line-height: 1.2;
    }
  }

  .buttons-wrap {
    text-align: center;
    margin-top: 40px;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: space-around;
    }
  }

  .second-line {
    font-size: 14px;
    line-height: 1.3;
    color: #6f6e6e;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 72px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  .success-telegram-message {
    text-align: center;
  }

  .telegram-link {
    text-align: center;
    margin-bottom: 36px;
  }

  .telegram-link a {
    color: #007bff;
    font-size: 15px;
  }

  .cta-btn.grey {
    color: grey;
  }
</style>
