import { render, staticRenderFns } from "./sport_competition_result_accepted.vue?vue&type=template&id=34db8796&scoped=true&"
import script from "./sport_competition_result_accepted.vue?vue&type=script&lang=js&"
export * from "./sport_competition_result_accepted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34db8796",
  null
  
)

export default component.exports