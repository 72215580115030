<template>
  <div class="events-list__item" :class="`events-list-item--status-${this.sport_event.status}`">
    <div  class="events-list__item__header">
      <div class="sport-item__time">
        {{ this.sport_event.start_date_from_now ? this.sport_event.start_date_from_now : this.startDateWeekDay }} {{ this.formattedTime() }}
      </div>
      <div class="sport-item__status" :class="`status-${this.sport_event.status}`">
        <div v-if="this.sport_event.status == 'waiting'">
          <div class="sport-item__participants">
            <font-awesome-icon icon="user-clock" />
            <b>{{ this.formattedParticipants() }}</b>
            {{ I18n.t("common.current_participants") }}
          </div>
        </div>

        <div v-if="this.sport_event.status == 'ready'">
          <div class="sport-item__participants">
            <font-awesome-icon icon="user-clock" />
            <b>{{ this.formattedParticipants() }}</b>
            {{ I18n.t("common.current_participants") }}
          </div>
        </div>

        <div v-if="this.sport_event.status == 'inprogress'">
          <div class="sport-item__participants">
            <font-awesome-icon icon="clock" />

            {{ I18n.t(`sport_events.statuses.${this.sport_event.status}`) }}
          </div>
        </div>

        <div v-if="this.sport_event.status == 'canceled'">
          <div class="sport-item__participants">
            <font-awesome-icon icon="times-circle" />

            {{ I18n.t(`sport_events.statuses.${this.sport_event.status}`) }}
          </div>
        </div>

        <div v-if="this.sport_event.status == 'finished'">
          <div class="sport-item__participants">
            <font-awesome-icon icon="check-circle" />

            {{ I18n.t(`sport_events.statuses.${this.sport_event.status}`) }}
          </div>
        </div>
        <!-- {{ I18n.t(`sport_events.statuses.${this.sport_event.status}`) }} -->
      </div>
    </div>
    <div class="sport-item__main">
      <div class="sport-item__main__left">
        <div class="sport-image-wrap">
          <img class="sport-item__image" :src="require(`../../images/${this.sport_event.sport_code}-image.jpg`)"></img>
          <div class="sport-item__name-wrap">
            <div class="sport-item__name">
              <h3>{{ I18n.t(`sports.${this.sport_event.sport_code}`) }}</h3>

              <div class="sport-item__description" v-for="level in this.sport_event.sport_levels">{{ I18n.t(`sport_events.levels.${level.toLowerCase()}`) }}</div>
            </div>
          </div>

          <div class="sport-item__sport-image-bottom-left">
            <div class="sport-item__place-wrap">
              <div class="sport-item__place">
                <font-awesome-icon icon="map-marker-alt" size="lg" />
                {{ this.sport_event.place }}
              </div>

              <div class="sport-item__distance" v-if="sport_event.distance">
                <span>{{ sport_event.distance }} км от вас</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sport-item__bottom">
      <div class="inviting-user-wrap">
        <div class="inviting-user">
          <div class="inviting-user__avatar">
            <a :href="invitingUserProfileLink()">
              <img :src="imgPath()">
            </a>
          </div>
          <a :href="invitingUserProfileLink()" style="margin-left: 3px;">
            @{{ this.inviting_user.nickname }}
          </a>
        </div>

        <div class="inviting-user-participant inviting-user__status" v-if="this.acceptedParticipantIds.includes(this.inviting_user.id)">
          {{ I18n.t("sport_events.invite.already_participant") }}
        </div>

        <div class="inviting-user-requested inviting-user__status" v-else-if="this.participantsRequestsIds.includes(this.inviting_user.id)">
          {{ I18n.t("sport_events.invite.requested") }}
        </div>

        <div class="inviting-user-not-participant inviting-user__status" v-else>
          {{ I18n.t("sport_events.invite.not_participant") }}
        </div>
      </div>

      <div style="display: flex; justify-content: center;" v-if="this.loading">
        <div class="loader"></div>
      </div>


      <div class="sport-item__link-wrap" v-else-if="!this.acceptedParticipantIds.includes(this.inviting_user.id) && !this.participantsRequestsIds.includes(this.inviting_user.id)">
        <a v-on:click.prevent.stop="inviteUser()" href="" class="btn cta-btn purple">
          пригласить
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import showAvatar from "../../utils/avatar"
  import showAlert from "../../utils/alert"

  export default {
    props: {
      inviting_user: Object,
      sport_event: Object,
      show_date: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        sportEvent: this.sport_event,
        loading: false
      }
    },
    methods: {
      sportImagePath() {
        return `../../images/${this.sport_event.sport_code}-image.jpg`
      },
      formattedPrice() {
        return `${this.sport_event.price} ${this.sport_event.currency}`
      },
      formattedTime() {
        const minutes = ('0' + this.sport_event.start_at_minutes).slice(-2)
        const time = `${this.sport_event.start_at_hour}:${minutes}`

        if (this.show_date && !this.sport_event.start_date_from_now) {
          return `${this.startDateStr} ${time}`
        } else {
          return time
        }
      },
      formattedParticipants() {
        return `${this.sport_event.current_participants_count}/${this.sport_event.minimal_participants_count}`
      },
      eventLink() {
        return `/sport_events/${this.sport_event.id}`
      },
      invitingUserProfileLink() {
        return `/users/${this.inviting_user.id}`
      },
      imgPath() {
        return showAvatar(this.inviting_user, 60)
      },
      inviteUser() {
        this.loading = true

        axios
          .post(
            `/api/v1/sport_event_users/participations`,
            {
              sport_event_user: {
                user_id: this.inviting_user.id,
                sport_event_id: this.sportEvent.id,
                action_type:  "request_participation_from_creator"
              }
            }
          )
          .then(response => {
            showAlert(I18n.t("sport_events.telegram_notifications.success.sport_event_request_from_creator_created"))

            this.sportEvent = response.data["sport_event"]
          })
          .catch(error => {
            showAlert(
              I18n.t("sport_events.show.notifications.failed_participation_request"),
              "alert"
            )
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    computed: {
      // I use this hack, cause its simpler for me right now to do it on back
      // but formatting date on frontend is much prettier idea
      startDateWeekDay() {
        return this.sport_event.start_date_formatted.split(",")[0]
      },
      startDateStr() {
        return this.sport_event.start_date_formatted.split(",")[1].trim()
      },
      acceptedParticipantIds() {
        return this.sportEvent.accepted_participant_ids
      },
      participantsRequestsIds() {
        return this.sportEvent.participants_request_ids
      }
    }
  }
</script>

<style scoped>
  .events-list__item {
    margin: 14px;
    width: 315px;
    justify-content: center;
    flex-basis: 46%;
    color: #4a4a4a;
    padding: 16px 22px;
    box-shadow: 0 1px 5px rgba(0,0,0,.15);
    border-radius: 5px;
    position: relative;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      width: 97%;
      flex-basis: 47.577%;
      margin: 20px 6px;
    }
  }

  .sport-item__link-wrap a {
    font-size: 14px;
  }

  .sport-item__link-wrap svg {
    margin-left: 12px;
    margin-bottom: 4px;
  }

  .sport-item__main__left__creator-participants {
    /*margin: 15px 0;*/
  }

  .sport-item__sport-image-bottom-left {
    position: absolute;
    bottom: 10px;
    left: 0;
    color: #e0e0e0;
    padding: 12px;
  }

  .sport-item__main {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    align-items: flex-end;
    width: 100%;
  }

  .loader {
    background: url("../../images/loader.gif");
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
  }

  .sport-item__image {
    width: 100%;
    height: 205px;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .sport-item__creator {
    width: 100px;
  }

  .inviting-user {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  .inviting-user-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .events-list__item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .cta-btn.orange {
    background-color: #fae19e;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    font-size: 18px;
    line-height: 28px;
    min-width: 140px;
  }

  .cta-btn.orange:hover {
    background-color: #f7d574;;
  }

  .font-awesome-wrapper {
    cursor: pointer;
  }

  .booking-info {
    margin-right: 4px;
  }

  .booking-info__not-booked svg {
    color: #dc1010;
  }

  .inviting-user__status {
    font-size: 13px;
  }

  .inviting-user__nickname {
    font-weight: 400;
    color: white;
    margin-left: 3px;
  }

  .inviting-user__nickname a {
    text-decoration: none;
    font-weight: 400;
  }

  .inviting-user__avatar img {
    object-fit: cover;
    border-radius:50%;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .sport-item__place-wrap {
    text-align: start;
    line-height: 1.2;
    font-size: 12px;
    margin-top: 8px;
  }

  .sport-item__place-name {
    font-weight: 600;
  }

  .sport-item__distance {
    margin-top: 3px;
  }

  .sport-item__distance span {
    font-size: 11px;
  }

  .sport-item__place {
  }

  .sport-item__place svg {
    font-size: 12px;
  }

  .sport-image-wrap {
    position: relative;
  }

  .sport-item__name {
    text-align: left;
  }

  .sport-item__name h3 {
    font-size: 16px;
    color: #fff;
  }

  .sport-item__name div {
    font-size: 12px;
    margin-bottom: 3px;
    color: #fff;
    opacity: .7;
  }

  .sport-item__name-wrap {
    background-color: rgba(0,0,0,.4);
    transition: all .1s ease-in-out;
    padding: 9px 13px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
  }

  .sport-item__status {
  }

  .status-waiting svg {
    color: #dcc079;
  }

  .status-ready svg {
    color: #446e82;
  }

  .status-inprogress svg {
    color: #68ad80;
  }

  .status-finished svg {
    color: #446f59;
  }

  .sport-item__main__left {
    width: 100%;
  }

  .sport-item__main__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
  }

  .sport-item__bottom {
    margin-top: 24px;
  }

  .status-canceled svg {
    color: #b55539;
  }

  .weekday {
    font-size: 14px;
    font-weight: bold;
  }

  .sport-item__participants {
    color: #504b4b;
    font-size: 13px;
    margin-top: 8px;
    text-transform: lowercase;
  }

  .sport-item__participants-and-time {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #d4cfcf;
    margin-bottom: 15px;
  }

  .sport-item__time {
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    line-height: 1;
    padding: 4px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
  }

  .sport-item__time-from-now {
    font-size: 10px;
    color: grey;
    font-weight: 400;
  }

  .free {
    color: #8dc91d !important;
  }

  .price.center {
    display: flex;
    align-items: center;
  }

  .price {
    font-weight: 500;
  }

  .price span {
    position: relative;
    white-space: nowrap;
    text-align: center;
    padding: 6px;
    font-size: 14px;
  }
</style>
