<template>
  <div class="users">
    <div class="page-title">{{ I18n.t("users.index.title", { users_count: this.users_count }) }}</div>

    <div v-if="this.selected_sport" style="margin-bottom: 24px; padding-bottom: 12px; border-bottom: 1px solid #e0dbdb;">
      <div style="margin-top: -18px; display: flex; align-items: center; font-size: 14px;">
        {{ I18n.t("users.filter.title") }}
        <div class="user-sport-item--small">
          <div class="sport-item__image--small" :class="this.selected_sport"></div>
        </div>
      </div>
      <div style="margin-bottom: 12px;">
        <a href="/users" class="light-link" style="font-size: 13px;">{{ I18n.t("users.filter.clear") }}</a>
      </div>
    </div>

    <div class="users-list">
      <div class="users-item" v-for="user in this.usersLocal">
        <div class="item-first-row">
          <div class="user-avatar-nickname-wrap">
            <div class="user-avatar-wrap">
              <a :href="`/users/${user.nickname}`">
                <div class="user-avatar">
                  <img :src="avatarPath(user)">
                </div>
              </a>

              <div class="user-nickname">
                <a :href="`/users/${user.nickname}`">{{ showName(user) }}</a>
              </div>
            </div>

            <div class="user-sports">
              <a :href="`/users?sport=${sport}`" v-for="(level, sport, index) in user.sports">
                <div class="user-sport-item">
                  <div class="sport-item__image" :class="sport"></div>
                  <div class="sport-item__level"> {{ level }}</div>
                </div>
              </a>
            </div>
          </div>

          <div :class="logoName(user.rank)" class="medal"></div>
        </div>

        <div class="item-second-row">
          <div v-if="user.success_taken_part_events_count > 0" class="successful-events">
            {{ I18n.t("users.profile.user_event_link", { count: user.success_taken_part_events_count } ) }}
          </div>
          <div class="current-geo-position">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            {{ `${user.current_geo}` }}
          </div>
        </div>
      </div>
    </div>

    <scroll-loader :loader-method="getUsersList" :loader-disable="disable">
    </scroll-loader>
  </div>
</template>

<script>
  import showAvatar from "../../utils/avatar"
  import axios from "axios"

  export default {
    props: {
      users: Array,
      users_count: Number,
      sports: Array,
      cities: Array,
      selected_sport: String
    },
    data: function() {
      return {
        disable: this.users.length < 20 ? true : false,
        page: 2,
        pageSize: 20,
        usersLocal: this.users,
      }
    },
    methods: {
      avatarPath(user) {
        return showAvatar(user, 50)
      },
      logoName(rank) {
        return `${rank}-medal`
      },
      showName(user) {
        if (user.nickname.includes("spotty_") && this.fullName(user))
          return this.fullName(user)

        return `@${user.nickname}`
      },
      fullName(user) {
        if (user.name || user.surname)
          return `${user.name ? user.name : ''} ${user.surname ? user.surname : ''}`;
      },
      getUsersList() {
        if(this.usersLocal.length < 20)
          return

        axios
          .get(
            "/api/v1/users",
            {
              params: {
                sport: this.selected_sport,
                page: this.page++,
                page_size: this.pageSize,
              }
            }
          )
          .then(response => {
            this.usersLocal = [...this.usersLocal, ...response.data]

            this.disable = response.data.length < this.pageSize
          })
          .catch(error => {
            console.log("ERROR: ", error)
          })
      }
    }
  }
</script>

<style scoped>
  .users {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 60px;
    }
  }

  .users-list {
    margin-top: 20px;

    font-size: 14px;

    padding-bottom: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  .users-item {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid #e0dbdb;
    padding-bottom: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .item-first-row {
    display: flex;
    margin-bottom: 10px;
    margin-right: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .current-geo-position {
    color: #635f5f;
    margin-left: 6px;
    font-size: 11px;
  }

  .user-avatar-nickname-wrap {
    display: flex;
    align-items: center;
  }

  .user-avatar-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .user-avatar img {
    border-radius: 30px;
    width: 50px;
    height: 50px;
    box-shadow: inset 0 2px 4px 0 hsl(0deg 0% 0% / 20%);
  }

  .user-nickname {
    margin-top: 6px;
    margin-left: 6px;
  }

  .user-nickname a {
    text-decoration: none;
  }

  .user-sports {
    display: flex;
    margin-left: 30px;
    margin-top: 10px;

    @media only screen and (max-width: 600px) {
      margin-left: 20px;
    }
  }

  .user-sport-item {
    margin-right: 24px;
    position: relative;
    background-color: #7d79b9;
    height: 40px;
    width: 40px;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
      height: 35px;
      width: 35px;
      margin-right: 18px;
    }
  }

  .user-sport-item--small {
    position: relative;
    background-color: #7d79b9;
    border-radius: 10px;
    height: 35px;
    width: 35px;
    margin-right: 18px;
    margin-left: 12px;

    @media only screen and (max-width: 600px) {
      margin-left: 6px;
    }
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10px;
    left: 10px;

    @media only screen and (max-width: 600px) {
      height: 20px;
      width: 20px;
      top: 8px;
      left: 8px;
    }
  }

  .sport-item__image--small {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  .successful-events {
    margin-left: 6px;
    margin-bottom: 8px;
    font-size: 12px;
    color: #635f5f;
  }

  .sport-item__level {
    position: absolute;
    bottom: -4px;
    right: -5px;
    font-size: 11px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    height: 15px;
    width: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .medal {
    margin-top: 5px;
  }

  .beginner-medal {
  }

  .bronze-medal {
    background: url("../../images/bronze_medal.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 25px;
  }

  .silver-medal {
    background: url("../../images/silver_medal.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 25px;
  }

  .gold-medal {
    background: url("../../images/gold_medal.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 35px;
  }
</style>
