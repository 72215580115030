<template>
  <a :href="this.notification.data.sport_competition_url" class="notification-item-wrap notification-item-wrap_loser">
    <div class="event-name">
      <a :href="this.notification.data.sport_competition_url">
        {{ I18n.t("sport_competitions.finish_title") }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span>
          {{ I18n.t("sport_competitions.notifications.sport_competition_notify_loser", { nickname: this.notification.data.profile_nickname, winner_user_score: this.notification.data.winner_user_score, loser_user_score: this.notification.data.loser_user_score }) }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
