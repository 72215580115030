<template>
  <nav id="menu">
    <header>
      <h2>Menu</h2>
    </header>
  </nav>
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      sports: Array,
      sport_schedules: Array,
      user_sports: Object,
    },
    methods: {
      pickedCategory: function(sport) {
        return this.user_sports[sport.code] || ""
      },
      filterSportSchedules: function(sport) {
        return _.filter(this.sport_schedules, { sport_id: sport.id })
      }
    }
  }
</script>

<style scoped>
</style>
