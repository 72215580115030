<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_message">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div v-html="renderMessage()">
        {{ I18n.t("sport_events.notifications.new_mass_chat_message", { messages_count: this.notification.data.messages_count }) }}
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    methods: {
      renderMessage() {
        return I18n.t(
          "sport_events.notifications.new_mass_chat_message",
          { messages_count: this.notification.data.messages_count }
        )
      }
    }
  }
</script>

<style scoped>
  .status {
    padding: 2px;
  }

  .notification-item-message b {
    margin: 0px 3px;
  }
</style>
