<template>
  <div class="user-show">
    <div class="top-content">
      <div class="avatar-background" v-if="$mq == 'sm'">
        <div class="user-avatar">
          <img :src="imgPath(this.user)" width="80" height="80" alt="profile photo" class="avatar-icon">
        </div>
        <div class="user-rank">
          <div :class="logoName(this.user.rank)"></div>
          <div class="new-rank-percentage" v-if="this.isCurrentUser() && this.user.new_rank_progress > 0">
            {{ this.user.new_rank_progress }}%
          </div>
        </div>
      </div>

      <div class="action-buttons"  v-if="$mq == 'sm'">
        <div>
          <div class="settings-button-wrap" v-if="this.isCurrentUser()">
            <a class="btn btn-sm cta-btn edit-button" href="/users/edit_profile">
              {{ I18n.t("sport_events.settings_button_change") }}
            </a>
          </div>

          <div class="invite-button-wrap" v-if="!this.currentUser.is_guest && !this.isCurrentUser()">
            <a class="btn cta-btn purple" :href="`/users/${this.user.id}/invite`">
              {{ I18n.t("users.profile.invite_button") }}
            </a>
          </div>

          <div style="margin-top: 24px; text-align: right;" v-if="this.user.telegram_link || this.user.instagram_link">
            <div v-if="this.user.telegram_link">
              <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path d="M15 9h-2V7a3 3 0 10-6 0v2H5V7a5 5 0 0110 0v2zM13 13v-2h2v2a5 5 0 01-10 0v-2h2v2a3 3 0 106 0z"></path><path d="M11 7H9v6h2V7z"></path></g></svg>
              <a target="_blank" :href="this.user.telegram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">telegram</a>
            </div>
            <div v-if="this.user.instagram_link" style="margin-top: 12px;">
              <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.774a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333z"></path><path d="M15.23 5.73a.96.96 0 11-1.92 0 .96.96 0 011.92 0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10 2c-2.173 0-2.445.01-3.299.048-.851.039-1.432.174-1.941.372a3.918 3.918 0 00-1.417.923c-.445.445-.719.89-.923 1.417-.198.509-.333 1.09-.372 1.941C2.01 7.555 2 7.827 2 10s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.205.526.478.972.923 1.417.445.445.89.718 1.417.923.509.198 1.09.333 1.942.372C7.555 17.99 7.827 18 10 18s2.445-.01 3.298-.048c.852-.039 1.434-.174 1.942-.372a3.918 3.918 0 001.417-.923 3.92 3.92 0 00.923-1.417c.198-.509.333-1.09.372-1.942.039-.853.048-1.125.048-3.298s-.01-2.445-.048-3.299c-.039-.851-.174-1.433-.372-1.941a3.918 3.918 0 00-.923-1.417 3.92 3.92 0 00-1.417-.923c-.51-.198-1.09-.333-1.942-.372C12.445 2.01 12.172 2 10 2zm0 1.441c2.136 0 2.389.009 3.232.047.78.036 1.204.166 1.486.275.373.146.64.319.92.599.28.28.453.546.598.92.11.282.24.705.275 1.485.039.844.047 1.097.047 3.233s-.008 2.389-.047 3.232c-.035.78-.165 1.204-.275 1.486-.145.373-.319.64-.598.92-.28.28-.547.454-.92.598-.282.11-.706.24-1.486.276-.843.038-1.096.046-3.232.046s-2.39-.008-3.233-.046c-.78-.036-1.203-.166-1.485-.276a2.481 2.481 0 01-.92-.598 2.474 2.474 0 01-.599-.92c-.11-.282-.24-.706-.275-1.486-.038-.843-.047-1.096-.047-3.232s.009-2.39.047-3.233c.036-.78.166-1.203.275-1.485.145-.374.319-.64.599-.92.28-.28.546-.454.92-.599.282-.11.705-.24 1.485-.275.844-.038 1.097-.047 3.233-.047z"></path></g></svg>
              <a target="_blank" :href="this.user.instagram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">instagram</a>
            </div>
          </div>
        </div>
      </div>

      <div class="avatar-rank-large-wrap" v-if="$mq != 'sm'">
        <div class="user-avatar-lg">
          <img :src="imgPath(this.user)" width="80" height="80" alt="profile photo" class="avatar-icon">
        </div>
        <div class="user-rank-lg">
          <div :class="logoName(this.user.rank)"></div>
          <div class="new-rank-percentage" v-if="this.isCurrentUser() && this.user.new_rank_progress > 0">
            {{ this.user.new_rank_progress }}%
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-content">
      <div class="user-main-info">
        <div class="user-main-info-left">
          <h2 class="user-info__name" style="margin-bottom: 6px;" v-if="!!this.fullName">
            {{ this.fullName ? this.fullName : "" }}
          </h2>
          <div class="nickname" :class="nicknameBig()">
            @{{ this.user.nickname }}
          </div>
          <div class="user-info__birthday" v-if="this.user.age">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.25 4.875C8.25 4.77554 8.28951 4.68016 8.35983 4.60984C8.43016 4.53951 8.52554 4.5 8.625 4.5H9.375C9.47446 4.5 9.56984 4.53951 9.64017 4.60984C9.71049 4.68016 9.75 4.77554 9.75 4.875V5.625C9.75 5.72446 9.71049 5.81984 9.64017 5.89016C9.56984 5.96049 9.47446 6 9.375 6H8.625C8.52554 6 8.43016 5.96049 8.35983 5.89016C8.28951 5.81984 8.25 5.72446 8.25 5.625V4.875Z" fill="black"/>
              <path d="M2.625 0C2.72446 0 2.81984 0.0395088 2.89016 0.109835C2.96049 0.180161 3 0.275544 3 0.375V0.75H9V0.375C9 0.275544 9.03951 0.180161 9.10983 0.109835C9.18016 0.0395088 9.27554 0 9.375 0C9.47446 0 9.56984 0.0395088 9.64017 0.109835C9.71049 0.180161 9.75 0.275544 9.75 0.375V0.75H10.5C10.8978 0.75 11.2794 0.908035 11.5607 1.18934C11.842 1.47064 12 1.85218 12 2.25V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V2.25C0 1.85218 0.158035 1.47064 0.43934 1.18934C0.720644 0.908035 1.10218 0.75 1.5 0.75H2.25V0.375C2.25 0.275544 2.28951 0.180161 2.35984 0.109835C2.43016 0.0395088 2.52554 0 2.625 0V0ZM0.75 3V10.5C0.75 10.6989 0.829018 10.8897 0.96967 11.0303C1.11032 11.171 1.30109 11.25 1.5 11.25H10.5C10.6989 11.25 10.8897 11.171 11.0303 11.0303C11.171 10.8897 11.25 10.6989 11.25 10.5V3H0.75Z" fill="black"/>
            </svg>

            {{ I18n.t("users.profile.age", { count: this.user.age }) }}
          </div>

          <div class="user-description">
            {{ this.user.description }}
          </div>

          <div class="main-info-footer">
            <div class="user-geo">
              <div class="user-info__current-place-wrap" v-if="this.isCurrentUser()">
                <div v-if="this.currentUser.street_name">
                  <div class="user-info__current-place">{{ `${this.currentUser.current_geo}` }}</div>
                  <div class="user-info__current-place__street-name" v-if="this.currentUser.street_name">
                    <font-awesome-icon icon="map-marker-alt" size="1x" />
                    {{ this.currentUser.street_name }}
                  </div>
                </div>

                <div v-else>
                  <div class="user-info__current-place">
                    <font-awesome-icon icon="map-marker-alt" size="1x" />
                    {{ this.currentUser.current_geo }}
                  </div>
                </div>
              </div>
              <div class="user-info__current-place-wrap" v-else>
                <div class="user-info__current-place">
                  <font-awesome-icon icon="map-marker-alt" size="1x" />
                  {{ `${this.user.current_geo}` }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="user-main-info-right">
          <div>
            <div class="settings-button-wrap" v-if="$mq != 'sm' && this.isCurrentUser()">
              <a class="btn btn-sm cta-btn edit-button" href="/users/edit_profile">
                {{ I18n.t("sport_events.settings_button_change") }}
              </a>
            </div>

            <div class="invite-button-wrap" v-if="$mq != 'sm' && !this.currentUser.is_guest && !this.isCurrentUser()">
              <a class="btn purple cta-btn" :href="`/users/${this.user.id}/invite`">
                {{ I18n.t("users.profile.invite_button") }}
              </a>
            </div>

            <div style="margin-top: 12px; text-align: right;" v-if="$mq != 'sm' && (this.user.telegram_link || this.user.instagram_link)">
              <div v-if="this.user.telegram_link">
                <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path d="M15 9h-2V7a3 3 0 10-6 0v2H5V7a5 5 0 0110 0v2zM13 13v-2h2v2a5 5 0 01-10 0v-2h2v2a3 3 0 106 0z"></path><path d="M11 7H9v6h2V7z"></path></g></svg>
                <a target="_blank" :href="this.user.telegram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">telegram</a>
              </div>
              <div v-if="this.user.instagram_link" style="margin-top: 12px;">
                <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.774a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333z"></path><path d="M15.23 5.73a.96.96 0 11-1.92 0 .96.96 0 011.92 0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10 2c-2.173 0-2.445.01-3.299.048-.851.039-1.432.174-1.941.372a3.918 3.918 0 00-1.417.923c-.445.445-.719.89-.923 1.417-.198.509-.333 1.09-.372 1.941C2.01 7.555 2 7.827 2 10s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.205.526.478.972.923 1.417.445.445.89.718 1.417.923.509.198 1.09.333 1.942.372C7.555 17.99 7.827 18 10 18s2.445-.01 3.298-.048c.852-.039 1.434-.174 1.942-.372a3.918 3.918 0 001.417-.923 3.92 3.92 0 00.923-1.417c.198-.509.333-1.09.372-1.942.039-.853.048-1.125.048-3.298s-.01-2.445-.048-3.299c-.039-.851-.174-1.433-.372-1.941a3.918 3.918 0 00-.923-1.417 3.92 3.92 0 00-1.417-.923c-.51-.198-1.09-.333-1.942-.372C12.445 2.01 12.172 2 10 2zm0 1.441c2.136 0 2.389.009 3.232.047.78.036 1.204.166 1.486.275.373.146.64.319.92.599.28.28.453.546.598.92.11.282.24.705.275 1.485.039.844.047 1.097.047 3.233s-.008 2.389-.047 3.232c-.035.78-.165 1.204-.275 1.486-.145.373-.319.64-.598.92-.28.28-.547.454-.92.598-.282.11-.706.24-1.486.276-.843.038-1.096.046-3.232.046s-2.39-.008-3.233-.046c-.78-.036-1.203-.166-1.485-.276a2.481 2.481 0 01-.92-.598 2.474 2.474 0 01-.599-.92c-.11-.282-.24-.706-.275-1.486-.038-.843-.047-1.096-.047-3.232s.009-2.39.047-3.233c.036-.78.166-1.203.275-1.485.145-.374.319-.64.599-.92.28-.28.546-.454.92-.599.282-.11.705-.24 1.485-.275.844-.038 1.097-.047 3.233-.047z"></path></g></svg>
                <a target="_blank" :href="this.user.instagram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">instagram</a>
              </div>
            </div>
          </div>

          <div class="sport-events-list-link">
            <a :href="`/users/${this.user.id}/sport_events`" class="profile-navigation-link ">
              {{ this.myEventsLinkText() }}
              <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 5.73684L1 10" stroke="#FFCB37" stroke-width="2"/>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="email-confirmation-alert" v-if="this.isCurrentUser()">
        <email-confirmation-alert :user="this.current_user"/>
      </div>

      <div class="user-additional-info">
        <div class="nearest-sport-event" v-if="nearest_event">
          <div class="nearest-sport-event__left-border"></div>
          <div class="nearest-sport-event__header">
            <div class="nearest-sport-event__title">{{ I18n.t("users.profile.nearest_event.title") }}</div>
            <div class="nearest-sport-event__time-in-words" v-if="['waiting', 'ready'].includes(this.nearest_event.status)">{{ this.nearest_event.start_after_in_words }}</div>
          </div>

          <div class="nearest-sport-event__footer">
            <div class="nearest-sport-event__footer__datetime">
              <div class="nearest-sport-event__footer__date-wrap">
                <div class="nearest-sport-event__footer__date-num">{{ this.nearest_event.start_date.split(".")[0] }}</div>
                <div class="nearest-sport-event__footer__date-month">{{ this.nearest_event.start_month_abbr }}</div>
              </div>

              <div class="nearest-sport-event__footer__wrap">
                <div class="nearest-sport-event__footer__time">{{ this.formattedTime(this.nearest_event)}}</div>
                <div class="nearest-sport-event__footer__sport">{{ I18n.t(`sports.${this.nearest_event.sport_code}`) }}</div>
              </div>
            </div>

            <div class="nearest-sport-event__footer__links-wrap">
              <div class="nearest-sport-event__footer__links-messages">
                <a :href="`/sport_events/${this.nearest_event.id}/chat`">
                  {{ I18n.t("users.profile.nearest_event.messages") }}
                </a>

                <div class="nearest-sport-event__footer__chat-unread-messages-counter" v-if="this.unread_chat_messages_count > 0 && $mq != 'sm'">
                  {{ this.unread_chat_messages_count }}
                </div>

                <div class="nearest-sport-event__footer__chat-unread-messages" v-if="this.unread_chat_messages_count > 0 && $mq == 'sm'">
                </div>
              </div>

              <div class="nearest-sport-event__footer__links-sport-event">
                <a :href="`/sport_events/${this.nearest_event.id}`" class="btn cta-btn purple btn-sm">
                  {{ I18n.t("users.profile.nearest_event.open_button") }}
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 5.73684L1 10" stroke="white"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="nearest-sport-event" v-else-if="latest_finished_event">
          <div class="latest-sport-event__left-border"></div>
          <div class="nearest-sport-event__header">
            <div class="nearest-sport-event__title">{{ I18n.t("users.profile.latest_finished_event.title") }}</div>
          </div>

          <div class="nearest-sport-event__footer">
            <div class="nearest-sport-event__footer__datetime">
              <div class="nearest-sport-event__footer__date-wrap">
                <div class="nearest-sport-event__footer__date-num">{{ this.latest_finished_event.start_date.split(".")[0] }}</div>
                <div class="nearest-sport-event__footer__date-month">{{ this.latest_finished_event.start_month_abbr }}</div>
              </div>

              <div class="nearest-sport-event__footer__wrap">
                <div class="nearest-sport-event__footer__time">{{ this.formattedTime(this.latest_finished_event) }}</div>
                <div class="nearest-sport-event__footer__sport">{{ I18n.t(`sports.${this.latest_finished_event.sport_code}`) }}</div>
              </div>
            </div>

            <div class="nearest-sport-event__footer__links-wrap">
              <div class="nearest-sport-event__footer__links-messages">
                <a :href="`/sport_events/${this.latest_finished_event.id}/review`">
                  {{ I18n.t("users.profile.latest_finished_event.reviews") }}
                </a>
              </div>

              <div class="nearest-sport-event__footer__links-sport-event">
                <a :href="`/sport_events/${this.latest_finished_event.id}`" class="btn cta-btn purple btn-sm">
                  {{ I18n.t("users.profile.latest_finished_event.open_button") }}
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 5.73684L1 10" stroke="white"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="user-competition" v-if="this.actual_competition">
          <div v-if="this.actual_competition.status == 'requested' && this.actual_competition.initiator.id == this.current_user.id">
            <div class="alert alert-warning" style="display: flex; align-items: center;">
              <div class="sport-alert-item-wrap">
                <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 25px; height: 25px;"></div>
              </div>

              <div>
                {{ I18n.t("users.profile.sport_competitions.waiting_response") }}
              </div>
            </div>
          </div>

          <div v-if="this.actual_competition.status == 'requested' && this.actual_competition.requested_user.id == this.current_user.id">
            <div class="alert alert-warning" style="display: flex; align-items: center;">
              <div class="sport-alert-item-wrap">
                <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 25px; height: 25px;"></div>
              </div>

              <div>
                {{ I18n.t("users.profile.sport_competitions.user_requested_competition", { nickname: this.actual_competition.initiator.nickname}) }}
                <a href="/sport_competitions">
                  {{ I18n.t("users.profile.sport_competitions.more_info_link") }}
                </a>
              </div>
            </div>
          </div>

          <div v-else-if="this.actual_competition.status == 'in_progress'" class="main-competition">
            <div class="section-title">
              {{ I18n.t("users.profile.sport_competitions.current_competition_title") }}

              <a :href="`/sport_competitions/${this.actual_competition.id}`" style="margin-left: 24px; font-weight: 450; text-decoration: none;">
                {{ I18n.t("users.profile.sport_competitions.more_info_link") }}
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 5.73684L1 10" stroke="#FFCB37" stroke-width="2"/>
                </svg>
              </a>
            </div>
            <div style="margin-top: 24px; display: flex;">
              <div v-if="this.actual_competition.initiator.id == this.current_user.id" style="display: flex;">
                <div style="display: flex; align-items: center;">
                  <a :href="`/users/${this.actual_competition.initiator.nickname}`">
                    <img style="margin-right: 24px" :src="imgPath(this.actual_competition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                  </a>

                  <div style="font-size: 30px">
                    {{ this.actual_competition.initiated_user_wins }}
                  </div>
                </div>
                <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
                <div style="display: flex; align-items: center; margin-right: 24px;">
                  <div style="font-size: 30px">
                    {{ this.actual_competition.requested_user_wins }}
                  </div>
                  <a :href="`/users/${this.actual_competition.requested_user.nickname}`">
                    <img style="margin-left: 24px" :src="imgPath(this.actual_competition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                  </a>
                </div>

                <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                  <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                    <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                  </div>

                  <div style="font-size: 13px; color: grey; margin-top: 6px;">до {{ this.actual_competition.count_of_wins }} побед</div>
                </div>
              </div>

              <div v-else style="display: flex;">
                <div style="display: flex; align-items: center;">
                  <a :href="`/users/${this.actual_competition.requested_user.nickname}`">
                    <img style="margin-right: 24px" :src="imgPath(this.actual_competition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                  </a>
                  <div style="font-size: 30px">
                    {{ this.actual_competition.requested_user_wins }}
                  </div>
                </div>
                <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
                <div style="display: flex; align-items: center; margin-right: 24px;">
                  <div style="font-size: 30px">
                    {{ this.actual_competition.initiated_user_wins }}
                  </div>
                  <a :href="`/users/${this.actual_competition.initiator.nickname}`">
                    <img style="margin-left: 24px" :src="imgPath(this.actual_competition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                  </a>
                </div>

                <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                  <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                    <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                  </div>

                  <div style="font-size: 13px; color: grey; margin-top: 6px;">до {{ this.actual_competition.count_of_wins }} побед</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="this.finished_competition">
          <div class="main-competition" style="margin-top: 24px;">
            <div class="section-title">
              {{ I18n.t("users.profile.sport_competitions.latest_competition_title") }}
              <a :href="`/sport_competitions/${this.finished_competition.id}`" style="margin-left: 24px; font-weight: 450; text-decoration: none;">
                {{ I18n.t("users.profile.sport_competitions.more_info_link") }}
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 5.73684L1 10" stroke="#FFCB37" stroke-width="2"/>
                </svg>
              </a>
            </div>

            <div v-if="this.finished_competition.initiator.id == this.current_user.id" style="display: flex; margin-top: 24px;">
              <div style="display: flex; align-items: center;">
                <a :href="`/users/${this.finished_competition.initiator.nickname}`">
                  <img style="margin-right: 24px" :src="imgPath(this.finished_competition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                </a>

                <div style="font-size: 30px">
                  {{ this.finished_competition.initiated_user_wins }}
                </div>
              </div>
              <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
              <div style="display: flex; align-items: center; margin-right: 24px;">
                <div style="font-size: 30px">
                  {{ this.finished_competition.requested_user_wins }}
                </div>
                <a :href="`/users/${this.finished_competition.requested_user.nickname}`">
                  <img style="margin-left: 24px" :src="imgPath(this.finished_competition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                </a>
              </div>

              <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                  <div :class="`sport-alert-item mini-${this.finished_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                </div>

                <div style="font-size: 13px; color: grey; margin-top: 6px;">до {{ this.finished_competition.count_of_wins }} побед</div>
              </div>
            </div>

            <div v-else style="display: flex;  margin-top: 24px;">
              <div style="display: flex; align-items: center;">
                <a :href="`/users/${this.finished_competition.requested_user.nickname}`">
                  <img style="margin-right: 24px" :src="imgPath(this.finished_competition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                </a>
                <div style="font-size: 30px">
                  {{ this.finished_competition.requested_user_wins }}
                </div>
              </div>
              <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
              <div style="display: flex; align-items: center; margin-right: 24px;">
                <div style="font-size: 30px">
                  {{ this.finished_competition.initiated_user_wins }}
                </div>
                <a :href="`/users/${this.finished_competition.initiator.nickname}`">
                  <img style="margin-left: 24px" :src="imgPath(this.finished_competition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                </a>
              </div>

              <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                  <div :class="`sport-alert-item mini-${this.finished_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                </div>

                <div style="font-size: 13px; color: grey; margin-top: 6px;">до {{ this.finished_competition.count_of_wins }} побед</div>
              </div>
            </div>

            <div style="margin-top: 12px; font-weight: 450; font-size: 14px;">
              <div v-if="this.finished_competition.winner_id == this.current_user.id" style="color: #009f00;">
                <font-awesome-icon icon="thumbs-up" size="1x" />
                {{ I18n.t("users.profile.sport_competitions.you_won") }}
              </div>

              <div v-else style="color: #b72d2d;">
                <font-awesome-icon icon="frown" size="1x" />
                {{ I18n.t("users.profile.sport_competitions.you_lose") }}
              </div>
            </div>
          </div>

          <div style="margin-top: 24px;">
            <a :href="`/sport_competitions/new?id=${this.user.id}`" class="btn cta-btn purple-light" style="font-size: 14px;">
              {{ I18n.t("users.profile.sport_competitions.request_competition") }}

              <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 5.73684L1 10" stroke="#6360AC" stroke-width="2"/>
              </svg>
            </a>
          </div>
        </div>

        <div class="user-competition" v-else-if="!this.current_user.guest && !this.isCurrentUser()">
          <div>
            <a :href="`/sport_competitions/new?id=${this.user.id}`" class="btn cta-btn purple-light" style="font-size: 14px;">
              {{ I18n.t("users.profile.sport_competitions.request_competition") }}

              <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 5.73684L1 10" stroke="#6360AC" stroke-width="2"/>
              </svg>
            </a>
          </div>
        </div>

        <div class="user-partners" v-if="this.user.partners.length > 0">
          <div class="section-title">
            {{ I18n.t("users.profile.main_partners.title") }}
          </div>

          <div class="user-partner-list">
            <div class="user-partner-item" v-for="(partner, index) in this.user.partners">
              <a :href="`/users/${partner.nickname}`">
                <img :src="imgPath(partner)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                <div style="font-size: 12px; font-weight: 500; margin-top: 6px;">@{{ partner.nickname.substring(0,16) }}</div>
              </a>
            </div>

            <div class="show-more-partners" v-if="this.user.partners_count > 3">
              <a :href="`/users/${this.user.id}/partners`">
                <div class="more-users-link">
                  + {{ this.user.partners_count - 3 }}
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="empty-user-partners" v-else-if="this.user.partners.length == 0 && !this.isCurrentUser()">
          {{ I18n.t("users.profile.main_partners.no_partners") }}
        </div>

        <div class="potential-user-partners" v-if="this.user.partners.length == 0 && this.currentUser.possible_partners && this.currentUser.possible_partners.length > 0 && this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.possible_partners.title") }}
          </div>

          <div class="user-partner-list">
            <div class="user-partner-item" v-for="(partner, index) in this.currentUser.possible_partners">
              <a :href="`/users/${partner.nickname}`">
                <img :src="imgPath(partner)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
                <div style="font-size: 12px; font-weight: 500; margin-top: 6px;">@{{ partner.nickname.substring(0,16) }}</div>
              </a>
            </div>
          </div>

          <div style="margin-top: 18px;">
            <a href="/users" class="light-link">{{ I18n.t("users.profile.possible_partners.show_more_link") }}</a>
          </div>
        </div>

        <div class="empty-user-partners" v-else-if="this.user.partners.length == 0 && this.currentUser.possible_partners && this.currentUser.possible_partners.length == 0 && this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.possible_partners.title") }}
          </div>
          <div style="margin-top: 12px; line-height: 1.2;">
            {{ I18n.t("users.profile.possible_partners.no_partners") }}
          </div>
        </div>

        <div class="competitions-link" style="margin-top: 24px;" v-if="this.isCurrentUser()">
          <a href="/sport_competitions" class="btn btn-sm cta-btn edit-button" style="position: relative;">
            {{ I18n.t("users.profile.sport_competitions.my_competitions") }}

            <span class="desktop-notification-counter" v-if="any_competition_actions"></span>

            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: 5px;">
              <path d="M1 1L6 5.73684L1 10" stroke="black" stroke-width="2"/>
            </svg>
          </a>
        </div>
        <div class="competitions-link" style="margin-top: 24px;" v-else>
          <a :href="`/sport_competitions?user_id=${this.user.id}`" class="btn btn-sm cta-btn edit-button">
            {{ I18n.t("users.profile.sport_competitions.competitions_with_user", { nickname: this.user.nickname }) }}

            <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: 5px;">
              <path d="M1 1L6 5.73684L1 10" stroke="black" stroke-width="2"/>
            </svg>
          </a>
        </div>

        <div class="user-sports-wrap">
          <div class="user-sports-header">
            <div class="section-title">
              {{ I18n.t("users.profile.sports.title") }}
            </div>
          </div>

          <div class="user-sports-wrap" v-if="Object.keys(this.user.sports).length > 0">
            <div class="user-sport-list">
              <a :href="`/users?sport=${sport}`" v-for="(level, sport, index) in this.user.sports">
                <div class="user-sport-item">
                  <div class="sport-item__image" :class="sport"></div>
                  <div class="sport-item__level">{{ level }}</div>
                </div>
              </a>
            </div>

            <div class="user-sports-more-link" v-if="Object.keys(this.user.sports).length > 0">
              <a :href="`/users/${this.user.id}/sports`" class="light-link">
                {{ I18n.t("sport_event.show_link") }}
              </a>
            </div>
          </div>

          <div v-else>
            <div v-if="this.isCurrentUser()" style="margin-top: 24px;">
              <a href="/user_settings/sports" class="btn cta-btn purple full-button">
                {{ I18n.t("sport_events.settings_button_set_up") }}
              </a>
            </div>

            <div style="margin-top: 24px; font-size: 14px; text-transform: lowercase;" v-else>
              {{ I18n.t("users.profile.sports.nothing_selected") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import showAvatar from "../../utils/avatar"
  import UserNewPlace from "../../components/users/modals/user_place.vue"
  import TelegramSubscriptionNotificationModal from "../../components/info_modals/telegram_subscription.vue"

  export default {
    props: {
      current_user: Object,
      user: Object,
      nearest_event: Object,
      latest_finished_event: Object,
      unread_chat_messages_count: Number,
      any_competition_actions: Boolean,
      actual_competition: Object,
      finished_competition: Object
    },
    data: function() {
      return {
        currentUser: this.current_user,
        userLinkActiveTab: "about"
      }
    },
    mounted() {
      if (!this.isCurrentUser() || !this.currentUser.can_remind_telegram_subscription)
        return

      this.$modal.show(TelegramSubscriptionNotificationModal,
        {},
        {
          pivotX: (this.$mq === "sm" ? 0 : 0.5),
          width: (this.$mq === "sm" ? "100%" : 600)
        }
      )
    },
    computed: {
      fullName() {
        if (this.user.name || this.user.surname)
          return `${this.user.name ? this.user.name : ''} ${this.user.surname ? this.user.surname : ''}`;
      }
    },
    methods: {
      formattedTime(event) {
        const minutes = ('0' + event.start_at_minutes).slice(-2)
        const time = `${event.start_at_hour}:${minutes}`

        return time
      },
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      changeLinkActiveItem(newName) {
        this.userLinkActiveTab = newName
      },
      userLinkActiveItem(itemName) {
        if (itemName == this.userLinkActiveTab)
          return "active-item"
      },
      logoName(rank) {
        return `${rank}-medal`
      },
      isCurrentUser() {
        return this.user.id == this.currentUser.id
      },
      openNewUserAdressModal() {
        this.$modal.show(UserNewPlace,
          { cities: this.cities, current_user: this.currentUser},
          {
            height: "auto",
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      nicknameBig() {
        return !this.fullName ? 'nickname-big' : ''
      },
      myEventsLinkText() {
        if (this.isCurrentUser())
          return I18n.t("users.profile.current_user_event_link")
        return I18n.t("users.profile.user_event_link", { count: this.user.taken_part_events_count })
      }
    }
  }
</script>

<style scoped>

  .user-show {
    padding-top: 50px;
    padding-bottom: 60px;

    @media only screen and (min-width: 600px) {
      display: flex;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      padding-top: 100px;
    }
  }

  .bottom-content {
    width: 100%;
  }

  .top-content {
    @media only screen and (min-width: 600px) {
      margin-right: 24px;
    }
  }

  .user-main-info {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;

    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f0;
    min-height: 110px;

    @media only screen and (max-width: 600px) {
      margin-top: 24px;
      align-items: flex-end;
    }
  }

  .desktop-notification-counter {
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;
    height: 6px;
  }

  .user-main-info-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .user-main-info-right {
    @media only screen and (max-width: 600px) {
      min-width: 100px;
    }

    @media only screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .user-additional-info {
    padding: 0px 15px;
    @media only screen and (max-width: 600px) {
      margin-top: 12px;
    }
  }

  .avatar-background {
    height: 85px;
    background-color: #679FB3;
    position: relative;
  }

  .user-header {
    text-align: center;
    position: relative;
  }

  .avatar-icon {
    border-radius: 50%;
    width: 100px;
    height: 100px;

    @media only screen and (max-width: 600px) {
      width: 80px;
      height: 80px;
      border: 2px solid white;
    }
  }

  .email-confirmation-alert {
    margin: 15px;
    font-size: 14px;
    line-height: 1.3;

    @media only screen and (max-width: 600px) {
      font-size: 13px !important;
    }
  }

  .send-email-link a {
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      font-size: 13px !important;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0px 15px;
    height: 41px;
  }

  .nickname {
    font-size: 15px;
    color: grey;
    font-weight: 450;
  }

  .nickname-big {
    @media only screen and (min-width: 600px) {
      font-size: 20px;
      color: black;
      font-weight: 550;
      margin-top: 6px;
    }
  }

  .user-description {
    font-size: 14px;
    margin-top: 24px;
    line-height: 1.2;
    max-width: 420px;
  }

  .user-rank {
    position: absolute;
    right: 15px;
    bottom: 10%;
    color: white;
  }

  .user-rank-lg {
  }

  .new-rank-percentage {
    font-size: 12px;
    text-align: center;
    margin-top: 12px;

    @media only screen and (max-width: 600px) {
      color: white;
    }
  }

  .settings-button-wrap, .invite-button-wrap {
    @media only screen and (max-width: 600px) {
      margin-top: 12px;
    }
  }

  .competitions-link a, .settings-button-wrap a {
    background: white;
    border: 1px solid #d8d8d8;
    font-size: 13px;
    font-weight: 450;
  }

  .invite-button-wrap a {
    font-size: 14px;
    font-weight: 450;
  }

  .user-links {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dedede;
  }

  .user-link-item {
    padding-top: 12px;
    margin-top: -1px;
    display: flex;
    align-items: flex-end;
    height: 30px;
    cursor: pointer;
  }

  .user-link-item span {
    font-size: 14px;
    margin-left: 5px;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .active-item {
    border-top: 1px solid black;
  }

  .user-about {
    padding-bottom: 36px;
    border-bottom: 1px solid #e6e6e6;
  }

  .user-info {
    font-size: 14px;
    margin: 0px 10px;
    margin-top: 36px;
  }

  .user-info__name {
    font-size: 22px;
    line-height: 1.19048;
    font-weight: 400;
    min-width: 80%;

    @media only screen and (max-width: 600px) {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .user-info__birthday {
    font-size: 13px;
    color: #979797;
    margin-top: 12px;
    height: 15px;
    display: flex;
    align-items: baseline;
  }

  .user-info__birthday svg {
    margin-right: 3px;
  }

  .user-info__current-place-wrap {
  }

  .user-info__current-place {
    font-size: 12px;
    font-weight: 450;

    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
  }

  .user-info__current-place__street-name {
    font-size: 12px;
    color: #979797;
    margin-top: 3px;

    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
  }

  .main-info-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .nearest-sport-event {
    margin-top: 24px;
    padding: 12px 12px;
    padding-left: 15px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgb(0 40 117 / 6%);
  }

  .latest-sport-event__left-border {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(180deg,#00b3bd,#00b3bd);
    border-top-left-radius: 4px;
  }

  .nearest-sport-event__left-border {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(180deg,#f5ad8a,#f2976a);
    border-top-left-radius: 4px;
  }

  .nearest-sport-event__title {
    text-transform: uppercase;
    font-size: 13px;
  }

  .nearest-sport-event__header {
    display: flex;
    justify-content: space-between;
  }

  .nearest-sport-event__time-in-words {
    font-size: 12px;
    color: #979797;
  }

  .nearest-sport-event__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    align-items: center;
  }

  .nearest-sport-event__footer__date-wrap {
    margin-right: 12px;
    position: relative;
  }

  .nearest-sport-event__footer__date-wrap:after {
    content: '';
    height: 22px;
    width: 1px;
    background: #BDBDBD;
    position: absolute;
    top: 4px;
    right: -12px;
  }

  .nearest-sport-event__footer__datetime {
    display: flex;
  }

  .nearest-sport-event__footer__date-num {
    font-size: 20px;
    font-weight: 450;
  }

  .nearest-sport-event__footer__time {
    font-size: 20px;
  }

  .nearest-sport-event__footer__date-month {
    font-size: 12px;
    color: #979797;
    margin-top: 6px;
    text-transform: lowercase;
  }

  .nearest-sport-event__footer__sport {
    font-size: 12px;
    color: #979797;
    margin-top: 6px;
    text-transform: lowercase;
  }

  .nearest-sport-event__footer__wrap {
    margin-left: 12px;
  }

  .nearest-sport-event__footer__links-wrap {
    display: flex;
    align-items: center;
  }

  .nearest-sport-event__footer__links-messages {
    margin-right: 24px;
    font-size: 12px;
    position: relative;
  }

  .nearest-sport-event__footer__links-messages a {
    font-weight: 450 !important;
    text-decoration: none;
    font-size: 13px;
  }

  .nearest-sport-event__footer__links-sport-event a {
    font-size: 12px;
  }

  .nearest-sport-event__footer__chat-unread-messages {
    position: absolute;
    top: -3px;
    right: -5px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    height: 6px;
  }

  .nearest-sport-event__footer__chat-unread-messages-counter {
    position: absolute;
    top: -7px;
    right: -10px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;

    @media only screen and (max-width: 600px) {
      font: 9px Verdana;
      top: -6px;
      right: -11px;
    }
  }

  .show-more-partners a {
    text-decoration: none;
  }

  .more-users-link {
    width: 50px;
    height: 50px;
    border: 2px solid #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #6f6f6f;
    font-size: 16px;
  }

  .user-sports-wrap {
    margin-top: 36px;
  }

  .user-sports-settings {
    margin-top: 12px;
  }

  .user-sports-settings a {
    font-size: 12px;
  }

  .profile-navigation-link {
    text-decoration: none;
    font-weight: 450;
    font-size: 13px;
    cursor: pointer;
  }

  .user-sport-title-wrap {
    display: flex;
  }

  .user-sport-item {
    margin-right: 24px;
    position: relative;
    /*background-color: #6460AC;*/
    background-color: #7d79b9;
    height: 50px;
    width: 50px;
    border-radius: 10px;
  }

  .sport-item__right {
    margin-left: 12px;
  }

  .user-sports-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .user-sports-more-link {
    margin-top: 6px;
  }

  .user-sports-more-link a {
    text-transform: lowercase;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }

  .user-avatar {
    position: absolute;
    top: 50px;
    left: 13px;
  }

  .user-avatar-lg {
  }

  .user-sports {
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .sport-item__name {
    font-size: 15px;
    font-weight: 500;
  }

  .sport-item__level {
    position: absolute;
    bottom: 1px;
    right: -5px;
    font-size: 11px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sport-events-list-link {
  }

  .sport-events-list-link svg {
    margin-left: 5px;
  }

  .user-sport-schedules-items-block {
    display: flex;
    margin-left: 31px;
  }

  .user-sport-schedules-item__schedule {
    margin-top: 6px;
    font-size: 12px;
  }

  .user-sport-schedules-items__weekday {
    font-weight: 450;
    text-transform: lowercase;
  }

  /* common */

  .full-button {
    width: auto;
    font-weight: 450;
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
  }

  .cta-btn.purple-light {
    background-color: #e0dfee;
    color: #6360ac;
  }

  .cta-btn.purple-light:hover {
    background-color: #bfbcdc;
  }

  .user-partners, .potential-user-partners {
    margin-top: 36px;
  }

  .user-partner-list {
    display: flex;
    margin-top: 24px;
  }

  .user-partner-item {
    margin-right: 24px;
    text-align: center;
  }

  .user-partner-item a {
    text-decoration: none;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .empty-user-partners {
    margin-top: 36px;
    margin-bottom: 36px;
    font-size: 14px;
  }

  /* previous css */

  .beginner-medal {
  }

  .bronze-medal {
    background: url("../../images/bronze_medal.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 36px;
    margin: 10px auto;
  }

  .silver-medal {
    background: url("../../images/silver_medal.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 36px;
    margin: 10px auto;
  }

  .gold-medal {
    background: url("../../images/gold_medal.svg");
    background-repeat: no-repeat;
    width: 40px;
    height: 46px;
    margin: 10px auto;
  }

  .user-sport-list {
    display: flex;
  }

  .section-title {
    font-size: 18px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .user-sport-item {

  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .user-sport-schedules-items {
    padding: 12px;
    padding-bottom: 0px;
    font-size: 13px;
  }

  .user-sport-schedules-items a {
    margin-bottom: 10px;
    margin-right: 2px;
  }

  .user-sport-schedules-items__sport {
    color: #946e1e;
    font-weight: 500;
    width: 100px;
  }

  .user-sport-schedules-items {
    display: flex;
  }

  .user-sport-schedules-item {
    margin-bottom: 10px;
  }

  .user-sport-schedules-items__weekday {
    font-weight: 500;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .mini-badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-competition {
    margin-top: 24px;
  }

  .main-competition {
    padding: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    background-color: #f7f6ff;

    @media only screen and (min-width: 600px) {
      max-width: 60%;
    }
  }
</style>
