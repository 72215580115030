<template>
  <div class="event-review">
    <div class="title">{{ I18n.t("sport_events.first_time_request.title") }}</div>
    <div class="event-review-wrap" v-if="!this.responseCompleted">
      <div class="textarea-wrap">
        <textarea class="form-control" id="review-textarea" v-model="message" :placeholder="I18n.t('sport_events.first_time_request.placeholder')" rows="5" maxlength="300"></textarea>
      </div>
      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn green" v-on:click.prevent.stop="participationRequest()">
          {{ I18n.t("sport_events.first_time_request.button") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      sportEventId: Number,
      participantId: Number
    },
    data: function() {
      return {
        responseCompleted: false,
        rating: null,
        message: ""
      }
    },
    methods: {
      participationRequest() {
        const requestParams = { sport_event_id: this.sportEventId }

        requestParams["user_id"] = this.participantId
        requestParams["message"] = this.message

        const successNotificationKey = "participation_request_created"

        // TODO: use error messages from response
        axios
          .post(
            `/api/v1/sport_event_users/participations/`,
            { sport_event_user: requestParams }
          )
          .then(response => {
            this.$eventHub.$emit("change-sport-event", response.data["sport_event"])
            this.$emit("close")
          })
          .catch(error => {
            showAlert(
              I18n.t("sport_events.show.notifications.failed_participation_request"),
              "alert"
            )
          })
          .then(() => {
          })
      }
    }
  }
</script>

<style scoped>
  .event-review {
    height: 100%;
  }

  .event-review-wrap {
    height: 80%;
    width: 229px;
    margin: 0px auto;
    margin-top: 20px;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 450;
    padding-top: 15px;
  }

  #review-textarea {
    font-size: 16px !important;
  }

  .submit-button-wrap {
    text-align: center;
    margin-top: 20px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }
</style>
