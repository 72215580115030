import { render, staticRenderFns } from "./request_message.vue?vue&type=template&id=f29db1da&scoped=true&"
import script from "./request_message.vue?vue&type=script&lang=js&"
export * from "./request_message.vue?vue&type=script&lang=js&"
import style0 from "./request_message.vue?vue&type=style&index=0&id=f29db1da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f29db1da",
  null
  
)

export default component.exports