<template>
  <div class="chat-message" :class="chatMessageHeaderCSS()">
    <div class="chat-message-header" v-if="full_message">
      <div>
        <a :href="authorUrl()">
         <img :src="imgPath()">
        </a>
      </div>

      <div class="header-message-wrap">
        <div class="header-message-name-time-wrap">
          <div class="chat-message-name" :class="creatorHeaderCss()">
            <a class="chat-message-name-link"  :href="authorUrl()">
              {{ message.author_nickname }}
            </a>
          </div>
          <div class="chat-message-time">
            {{ message.created_at_time }}
          </div>
        </div>
        <div class="chat-message-content chat-message-content__header" v-linkified>
          <!---->{{ message.body }}<!---->
        </div>
      </div>
    </div>

    <div class="chat-message-content" v-linkified v-else>
      <!---->{{ message.body }}<!---->
    </div>
  </div>
</template>

<script>
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      message: Object,
      full_message: Boolean,
      author: Object,
      isAuthorCreator: Boolean
    },
    data: function() {
      return {
      }
    },
    methods: {
      authorUrl() {
        return `/users/${this.message.author_id}`
      },
      imgPath() {
        return showAvatar(this.author, 50)
      },
      chatMessageHeaderCSS() {
        let headerCss = ""

        if (!this.message.viewed)
          headerCss += "not-viewed"

        if (this.full_message)
          headerCss += " chat-message__full"

        return headerCss
      },
      creatorHeaderCss() {
        if (!this.isAuthorCreator)
          return ""
        return "creator-name"
      },
    }
  }
</script>

<style scoped>
  .chat-message {
    padding: 5px;
    font-size: 14px;
/*
    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }*/
  }

  .chat-message__full {
    margin-top: 15px;
  }

  .creator-name a {
    color: #583f00 !important;
  }

  .not-viewed {
    background-color: whitesmoke;
    background-color: #f7f7f7;

  }

  .chat-message-header {
    display: flex;
  }

  .chat-message-header img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .chat-message-name {
    font-weight: 600;
    word-break: break-word;
    margin-left: 9px;
    margin-right: 3px;
  }

  .chat-message-name a {
    color: #1d1c1d;
  }

  .chat-message-time {
    color: hsla(220,8%,51%,.6);
    font-size: 10px;
    z-index: 2;
    margin-left: 4px;
    line-height: 1.4;
  }

  .chat-message-content {
    margin-top: -9px;
    text-align: left;
    min-width: 1px;
    line-height: 1.26668;
    margin: 0px 0 3px;
    padding-left: 40px;
    white-space: pre-line;
    word-wrap: break-word;
    font-family: inherit;
  }

  .chat-message-content__header {
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .header-message-name-time-wrap {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 6px;
  }
</style>
