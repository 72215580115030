export default function(text, alertType = "success") {
  var className = ""

  if (alertType == "success") {
    var className = ".success-notification-top"
  }

  if (alertType == "alert") {
    var className = ".alert-notification-top"
  }

  $(className).html(text)
  $(className).show()

  setTimeout(function() {
    $(className).hide()
    $(className).html("")
  }, 5000)
}
