import { render, staticRenderFns } from "./place_info.vue?vue&type=template&id=3eca13ec&scoped=true&"
import script from "./place_info.vue?vue&type=script&lang=js&"
export * from "./place_info.vue?vue&type=script&lang=js&"
import style0 from "./place_info.vue?vue&type=style&index=0&id=3eca13ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eca13ec",
  null
  
)

export default component.exports