import axios from "axios"

export default function(user) {
  const vapidPublicKey = new Uint8Array(JSON.parse(document.body.dataset.vapidPublicKey));

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    console.log("Permission to receive notifications has been granted");
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied' && user.ready_for_webpush_request) {
    axios.post("/api/v1/request_webpush_subscriptions").then(response => {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            serviceWorkerRegistration.pushManager.getSubscription()
            .then((subscription) => {

              if (subscription) {
                return subscription;
              }

              return serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey
              });
            }).then((subscription) => {
              axios.post("/api/v1/webpush_subscriptions", { subscription: subscription.toJSON() })
            })
          });
        }
      });
    })
    .catch(error => {
    })
    .then(() => {
    })
  }

  else {
    console.log("Don't know what to do :(");
  }
}
