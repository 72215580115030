<template>
  <div class="step-progress__wrapper">
    <div class="step-progress__wrapper-before"></div>
    <div class="step-progress__bar">
      <div class="step-progress__step" v-for="(step, index) in steps" :class="stepClass(index)">
        <div class="step-progress__step-icon">
          <font-awesome-icon icon="check" size="xs" />
        </div>
        <div class="step-progress__step-label" style="font-size: 12px;">{{ step }}</div>
      </div>
    </div>
    <div class="step-progress__wrapper-after" :class="stepProgressAfterColor()" :style="{transform: &quot;scaleX(&quot; + scaleX + &quot;) translateY(-50%) perspective(1000px)&quot;}"></div>
  </div>
</template>

<script>

export default {
  name: "StepProgress",
  props: {
    steps: {
      type: Array,
      default() {return [];},
      validator(val) {
        return val && val.length > 0;
      }
    },
    currentStep: {
      type: Number,
      default: 0
    },
    lastItemFailed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    scaleX() {
      let step = this.currentStep;
      if(step < 0) {
        step = 0;
      } else if (step >= this.steps.length) {
        step = this.steps.length - 1;
      }
      return step / (this.steps.length - 1);
    }
  },
  methods: {
    stepClass(index) {
      if (index == this.currentStep && this.lastItemFailed) {
        return "step-progress__step--failed"
      } else if (index === this.currentStep) {
        return `step-progress__step--valid step-progress__step--valid${index+1}`
      } else if(index < this.currentStep) {
        return "step-progress__step--active"
      } else {
        return ""
      }
    },
    stepProgressAfterColor() {
      if (this.lastItemFailed) {
        return "red-color"
      }
    }
  }
};
</script>

<style lang="sass">
.red-color{
  background-color: #b90505
}

.step-progress__wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 767px) {
    width: 78%;
  }
}
.step-progress__wrapper-before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  width: 100%;
  background-color: gray;
  transform: translateY(-50%) perspective(1000px);
}

.step-progress__wrapper-after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  width: 100%;
  background-color: #0b6135;
  transform: scaleX(0) translateY(-50%) perspective(1000px);
  transform-origin: left center;
  transition: transform .5s ease;
}

.step-progress__bar {
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.step-progress__step-label {
  position: absolute;
  top: calc(100% + 25px);
  left: 10px;
  transform: translateX(-50%) perspective(1000px);
  white-space: unset;
  font-size: 14px;
  font-weight: 600;
  color: gray;
  transition: .3s ease;
  line-height: 16px;
  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 500;
  }
}

.step-progress__step-icon {
  font-size: 26px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform .3s ease;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
  @media (max-width: 767px) {
    font-size: 22px;
  }
}

.step-progress__step {
  z-index: 2;
  position: relative;
  span {
    color: gray;
    transition: .3s ease;
    display: block;
    font-size: 10px;
    transform: translate3d(0,0,0) scale(1) perspective(1000px);
    font-weight: 600;
    text-align: center;
    opacity: 1;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  &--active
    span {
      color: #0b6135;
    }
    .step-progress__step-label {
      color: #0b6135;
    }
    .step-progress__step-icon {
      opacity: 1;
    }
  &--valid {
    .step-progress__step-label {
      font-weight: 600;
      color: black;
    }
  }
  &--valid1 {
    .step-progress__step-icon {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
    }

    span {
      color: #0b6135;
      opacity: 0;
      transform: translate3d(0,0,0) scale(2) perspective(1000px);
    }

    .step-progress__step-label {
      color: #0b6135;
    }
  }

  &--valid2 {
    .step-progress__step-icon {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
    }
    span {
      color: #0b6135;
      opacity: 0;
      transform: translate3d(0,0,0) scale(2) perspective(1000px);
    }

    .step-progress__step-label {
      color: #0b6135;
    }
  }

  &--valid3 {
    .step-progress__step-icon {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
    }

    span {
      color: #0b6135;
      opacity: 0;
      transform: translate3d(0,0,0) scale(2) perspective(1000px);
    }

    .step-progress__step-label {
      color: #0b6135;
    }
  }

  &--valid4 {
    .step-progress__step-icon {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
    }

    span {
      color: #0b6135;
      opacity: 0;
      transform: translate3d(0,0,0) scale(2) perspective(1000px);
    }

    .step-progress__step-label {
      color: #0b6135;
    }
  }

  &--failed {
    .step-progress__step-icon {
      opacity: 0;
      transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
    }

    span {
      color: #b90505;
      opacity: 1;
      transform: translate3d(0,0,0) scale(2) perspective(1000px);
    }

    .step-progress__step-label {
      color: #b90505;
    }
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) perspective(1000px);
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 50%;
    border: 5px solid gray;
    transition: .3s ease;
    @media (max-width: 767px) {
      width: 5px;
      height: 5px;
    }
  }

  &--active:after {
    border: 5px solid #0b6135;
  }
  &--valid1:after {
    background-color: black;
    border: 5px solid black;
    width: 35px;
    height: 35px;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }

  &--valid2:after {
    background-color: black;
    border: 5px solid black;
    width: 35px;
    height: 35px;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }

  &--valid3:after {
    background-color: black;
    border: 5px solid black;
    width: 35px;
    height: 35px;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }

  &--valid4:after {
    background-color: #0b6135;
    border: 5px solid #0b6135;
    width: 35px;
    height: 35px;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }
  &--failed:after {
    border: 5px solid #b90505;
  }
}
</style>
