<template>
  <div class="reject-participant-from-creator">
    <div class="title">{{ I18n.t("sport_events.reject_participant_from_creator.title") }}</div>

    <div v-if="responseCompleted" class="completed-info-wrap">
      <div class="completed-info">
        <div class="completed-info-img">
          <img src="../../../images/success.png">
        </div>
        {{ I18n.t("sport_events.reject_participant_from_creator.success_completed") }}
      </div>

      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.close") }}
        </a>
      </div>
    </div>

    <div v-else>
      <div class="inputs-wrap">
        <div v-for="purpose in this.purposes">
          <input :id="purpose" type="radio" :value="purpose" v-model="selectedPurpose">
          <label :for="purpose">
            {{ I18n.t(`sport_events.reject_participant_from_creator.${purpose}`) }}
          </label>
        </div>
      </div>
      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn purple" v-on:click.prevent.stop="sendResponse()">
          {{ I18n.t("common.buttons.reject") }}
        </a>

        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.back") }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      participantId: Number,
      sportEventId: Number
    },
    data: function() {
      return {
        responseCompleted: false,
        selectedPurpose: null,
        purposes: ["no_places", "insufficient_level", "other"]
      }
    },
    computed: {
    },
    methods: {
      sendResponse() {
        const requestParams = {
          sport_event_id: this.sportEventId,
          user_id: this.participantId,
          purpose: this.selectedPurpose
        }

        // TODO: use error messages from response
        axios
          .post(
            `/api/v1/sport_event_users/participations/reject_by_creator`,
            { sport_event_user: requestParams }
          )
          .then(response => {
            this.responseCompleted = true
            this.$eventHub.$emit("change-sport-event", response.data["sport_event"])
          })
          .catch(error => {
            showAlert(
              I18n.t("sport_events.show.notifications.failed_participation_request"),
              "alert"
            )
          })
      }
    }
  }
</script>

<style scoped>
  .reject-participant-from-creator {
    height: 100%;
  }

  .completed-info-wrap {
    height: 70%;
    padding-top: 20px;
  }

  .completed-info {
    height: 80%;
    text-align: center;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 450;
    padding-top: 15px;
    font-weight: bold;
  }

  .completed-info-img img {
    width: 120px;
    height: 120px;
  }

  .reject-participant-from-creator input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .reject-participant-from-creator .inputs-wrap {
    text-align: center;
    margin: 25px auto;
  }

  .reject-participant-from-creator label {
    display: inline-block;
    background-color: #efeff6;
    padding: 12px 18px;
    font-size: 14px;
    border: 1px solid #c3c3c3;
    color: #232946;
    font-weight: 500;
    border-radius: 5px;
    width: 80%;
  }

  .reject-participant-from-creator input[type="radio"]:checked + label {
    background-color: #8e8ac1;
    color: #fff;
    border-color: #FFF;
  }

  .reject-participant-from-creator label:hover {
    background-color: #cecee3;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }

  .submit-button-wrap {
    text-align: center;

    @media only screen and (max-width: 600px) {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }

  .submit-button-wrap .green {
    margin-right: 10px;
  }
</style>
