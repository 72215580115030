<template>
  <div class="place-map">
    <div class="place-name">{{ sportEventName }}</div>

    <div id="map"></div>
  </div>
</template>

<script>
  import axios from "axios"
  import { validators } from "vue-form-generator"

  export default {
    props: {
      sportEventPlaceLatitude: Number,
      sportEventPlaceLongitude: Number,
      sportEventName: String
    },
    data: function() {
      return {
      }
    },
    mounted() {
      const placeCoords = { lat: this.sportEventPlaceLatitude, lng: this.sportEventPlaceLongitude };

      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        center: placeCoords,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      });

      const marker = new google.maps.Marker({
        position: placeCoords,
        map: map,
      });
    }
  }
</script>

<style scoped>
  .place-map {
    width: 100%;
    height: 100%;
  }

  .place-name {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 24px;
  }

  #map {
    width: 100%;
    height: 79%;
  }
</style>
