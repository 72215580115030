<template>
  <div class="sport-schedule-list">
    <user-sport-schedules-form-item v-for="(sport_schedule, index) in local_sport_schedules" :key="sport_schedule.id + index" v-on:check-sport-schedules-validity="checkValidity" v-on:remove-sport-schedule="removeSportSchedule($event)"  :sport_schedule="sport_schedule" :sport_id="sport_id" :index="index" />
    <button @click="addEmptySportSchedule" type="button" class="btn btn-success btn-sm" v-if="$mq == 'sm'">{{ this.addButtonName }}</button>
    <button @click="addEmptySportSchedule" type="button" class="btn btn-success btn-sm" v-else>{{ this.addButtonName }}</button>
  </div>
</template>

<script>
  import _ from "lodash"

  export default {
    props: {
      sport_id: Number,
      sport_schedules: Array,
    },
    data: function() {
      return {
        local_sport_schedules: this.sport_schedules,
      }
    },
    created: function() {
      this.checkValidity()
    },
    methods: {
      checkValidity: function() {
        const validItem = _.find(this.local_sport_schedules, item => !!item.week_day && !!item.time_from && !!item.time_to)

        if (validItem) {
          this.$emit("change-sport-schedules-validity", true)
        } else {
          this.$emit("change-sport-schedules-validity", false)
        }
      },
      addEmptySportSchedule: function() {
        this.local_sport_schedules.push({
          id: null,
          week_day: "",
          time_from: "",
          time_to: "",
          _destroy: null,
        })
      },
      removeSportSchedule: function(index) {
        let sportScheduleItem = this.local_sport_schedules[index]

        if (sportScheduleItem.id == null) {
          this.local_sport_schedules.splice(index, 1)
        } else {
          this.$set(sportScheduleItem, '_destroy', "1")
        }
      }
    },
    computed: {
      addButtonName() {
        if (this.local_sport_schedules.length == 0) {
          return I18n.t("sport_schedule.create")
        } else {
          return I18n.t("sport_schedule.add_more")
        }
      }
    }
  }
</script>

<style scoped>
</style>
