import axios from "axios"

export default {
  methods: {
    updateStep(stepNumber) {
      axios
        .post("/user_settings/steps/change_step/",
          {
            step_number: stepNumber
          }
        )
        .then(response => {
          Turbolinks.visit("/")
        })
        .catch(error => {
          //
        })
    }
  }
}
