<template>
  <div class="third-step">
    <div class="step-counters"><div class="step-counters">{{ I18n.t("registration.current_step", { current_step_number: 3 }) }}</div></div>
    <div class="success-notification-title">
      <div class="first-line">{{ I18n.t("registration.third_step.info_text") }}</div>

      <div class="errors" v-if="this.errors">
        {{ this.errors }}
      </div>

      <div class="city">
        {{ I18n.t("registration.third_step.city_description") }} {{ `${city}(${country})` }}
      </div>

      <div class="field-wrap form-group autocomplete-wrap">
        <input required ref="autocomplete" :placeholder="I18n.t('sport_events.new_form.address')" class="form-control" onfocus="value = ''" v-model="newPlace.street_name" required="true" type="text">
      </div>

      <div class="second-line">
        <p>
          {{ I18n.t("registration.third_step.first_description") }}<br>
          {{ I18n.t("registration.third_step.second_description") }}
        </p>

        <p>
          {{ I18n.t("registration.third_step.third_description") }}
        </p>
      </div>
    </div>

    <nav class="navbar fixed-bottom navbar-light bg-light registration-buttons-wrap" v-if="$mq === 'sm'">
      <div class="buttons-wrap">
        <button class="btn cta-btn grey" style="margin-right: 10px;" v-on:click.prevent="updateStep(4)">
          {{ I18n.t("registration.buttons.skip_step") }}
        </button>
        <button class="btn cta-btn purple" v-on:click.prevent="sendResponse()">
          <font-awesome-icon icon="arrow-circle-right" size="1x" />
          {{ I18n.t("registration.third_step.buttons.next") }}
        </button>
      </div>
    </nav>

    <div class="buttons-wrap" v-if="$mq !== 'sm'">
      <button class="btn cta-btn grey" style="margin-right: 10px;" v-on:click.prevent="updateStep(4)">
        {{ I18n.t("registration.buttons.skip_step") }}
      </button>
      <button class="btn cta-btn purple" v-on:click.prevent="sendResponse()">
        <font-awesome-icon icon="arrow-circle-right" size="1x" />
        {{ I18n.t("registration.third_step.buttons.next") }}
      </button>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import updateStepMixin from "../mixins/update_step"

  export default {
    props: {
      city: String,
      country: String,
    },
    data: function() {
      return {
        errors: null,
        newPlace: {}
      }
    },
    mixins: [updateStepMixin],
    mounted() {
      if (!this.$refs.autocomplete) {
        return
      }

      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ["address"], language: "en" }
      );

      this.autocomplete.addListener("place_changed", () => {
        try {
          let place = this.autocomplete.getPlace();

          let ac = place.address_components;
          let lat = place.geometry.location.lat();
          let lon = place.geometry.location.lng();
          console.log("COMPONENTS", place.address_components)

          let cityName = _.find(place.address_components, function(o) {
            return _.isEqual(o.types, ["locality", "political"]) ||
                     _.isEqual(o.types, ["administrative_area_level_3", "political"]) ||
                         _.isEqual(o.types, ["postal_town"])
          });

          this.newPlace.latitude = lat;
          this.newPlace.longitude = lon;
          this.newPlace.google_place_id = place.place_id;
          this.newPlace.street_name = place.name;

          if(cityName) {
            this.newPlace.city_name = cityName["long_name"];
          }
        } catch(e) {
          this.errors = I18n.t("sport_events.errors.address_error")
          console.log("ERROROROR")
        }
      });
    },
    methods: {
      sendResponse() {
        this.errors = null

        if (!this.newPlace.google_place_id) {
          this.updateStep(4)
          return
        }

        axios
            .post("/api/v1/user_places/",
              {
                user_place: {
                  ...this.newPlace
                }
              }
            )
            .then(response => {
              this.responseCompleted = true
              this.updateStep(4)
            })
            .catch(error => {
              this.errors = I18n.t("sport_events.errors.address_error")
            })
      }
    }
  }
</script>

<style scoped>
  .third-step {
    font-size: 14px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding: 0px 15px;
      padding-top: 65px;
    }
  }

  .success-notification-title {
    text-align: center;
    margin-bottom: 48px;

    @media only screen and (max-width: 600px) {
      margin-top: 10px;
      text-align: left;
    }
  }

  .success-notification-title .first-line {
    font-weight: 450;
    margin-bottom: 24px;
    margin-top: 24px;

    @media only screen and (max-width: 600px) {
      line-height: 1.2;
    }
  }

  .success-notification-title .second-line {
    font-size: 14px;
    line-height: 1.3;
    color: #6f6e6e;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .city {
    margin-bottom: 36px;
  }

  .autocomplete-wrap {
    width: 80%;
    margin-bottom: 36px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .errors {
    color: #e65328;
    text-align: center;
    padding-bottom: 20px;
    font-size: 14px;
  }

  .buttons-wrap {
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: space-around;
    }
  }

  .registration-buttons-wrap {
    @media only screen and (max-width: 600px) {
      justify-content: space-around;
    }
  }

  .cta-btn.grey {
    color: grey;
  }
</style>
