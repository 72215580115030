<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_clock">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span>
          {{
            I18n.t(
            "sport_events.notifications.sport_event_not_enough_participants",
              {
                current_participants_count: this.notification.data.current_participants_count,
                minimal_participants_count: this.notification.data.minimal_participants_count,
              }
            )
          }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
