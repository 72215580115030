<template>
  <div class="sport-competition">
    <a class="back-link" :href="`/sport_competitions`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq != 'sm'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div style="margin: 24px 0px;">
      <div style="display: flex; align-items: center;">
        <div style="display: flex; align-items: center;">
          <a :href="`/users/${localCompetition.requested_user.nickname}`" style="margin-right: 24px; text-align: center;">
            <img  :src="imgPath(localCompetition.requested_user)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
            <div style="margin-top: 3px;">
              @{{ localCompetition.requested_user.nickname }}
            </div>
          </a>
          <div style="font-size: 30px">
            {{ localCompetition.requested_user_wins }}
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
        <div style="display: flex; align-items: center; margin-right: 24px;">
          <div style="font-size: 30px">
            {{ localCompetition.initiated_user_wins }}
          </div>
          <a :href="`/users/${localCompetition.initiator.nickname}`" style="margin-left: 24px; text-align: center;">
            <img :src="imgPath(localCompetition.initiator)" width="30" height="30" alt="profile photo" class="user-partner-avatar-icon">
            <div style="margin-top: 3px;">
              @{{ localCompetition.initiator.nickname }}
            </div>
          </a>
        </div>

        <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
          <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
            <div :class="`sport-alert-item mini-${localCompetition.sport_code}`" style="width: 20px; height: 20px;"></div>
          </div>

          <div style="font-size: 13px; color: grey; margin-top: 6px;">до {{ localCompetition.count_of_wins }} побед</div>
        </div>
      </div>

        <sport-competition-result-submission
          v-if="localCompetition.all_results.length > 0"
          v-for="result in localCompetition.all_results"
          :key="result.id"
          :currentUserId="current_user.id"
          :result="result"
        />
    </div>
  </div>
</template>

<script>
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      competition: Object,
      current_user: Object
    },
    data() {
      return {
        localCompetition: this.competition
      }
    },
    created() {
      this.$eventHub.$on("change-sport-competitions", this.resetSportCompetition)
    },
    methods: {
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      resetSportCompetition(newCompetition) {
        console.log("NEW competition:", newCompetition)
        this.localCompetition = newCompetition.sport_competition
      },
    }
  }
</script>

<style scoped>
  .sport-competition {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .sport-competition a {
    font-size: 13px;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .mini-badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
</style>
