<template>
  <a :href="this.notification.data.sport_event_url" class="notification-item-wrap notification-item-wrap_minus">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.notification.data.sport_event_url">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div>
      <div class="notification-item">
        <div class="notification-item-message">
          <span class="notification-item-img">
            <a :href="this.notification.data.profile_url">
              <img :src="this.notification.data.profile_image" v-if="!!this.notification.data.profile_image">
              <span v-else class="nickname">@{{ this.notification.data.profile_nickname }}</span>
            </a>
          </span>
          <span class="notification-item-img-msg">
            {{ I18n.t("sport_events.notifications.sport_event_refused_by_participant") }}
          </span>
        </div>
      </div>

      <div v-if="!!this.notification.data.refuse_message" class="refuse-message">
        {{ this.notification.data.refuse_message }}
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
  }
</script>

<style scoped>
  .status {
    padding: 2px;
  }

  .refuse-message {
    margin-top: 5px;
    font-weight: 400;
    font-style: italic;
  }
</style>
