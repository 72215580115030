import { render, staticRenderFns } from "./sport_event_not_enough_participants.vue?vue&type=template&id=24b98152&scoped=true&"
import script from "./sport_event_not_enough_participants.vue?vue&type=script&lang=js&"
export * from "./sport_event_not_enough_participants.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b98152",
  null
  
)

export default component.exports