<template>
  <div class="place-inputs" ref="placeModal">
    <div class="main-info" v-if="this.activePage == 'main'">
      <div class="page-title" style="font-size: 16px;">
        {{ I18n.t('sport_events.new_form.place.title') }}
      </div>
      <div class="new-place-wrap" v-if="!this.responseCompleted">
        <div class="errors" v-if="this.errors">
          {{ this.errors }}
        </div>

        <div class="field-wrap form-group vue-form-generator col-xs-12" style="width: 100%;">
          <label>{{ I18n.t("sport_events.new_form.address") }}</label>
          <input required ref="autocomplete" :placeholder="I18n.t('sport_events.new_form.address')" class="form-control" onfocus="value = ''" v-model="newPlace.formatted_address" required="true" type="text" autocomplete="new-password">
        </div>

        <vue-form-generator :model="newPlace"
                            :schema="this.placeTabSchema"
                            :options="this.formOptions"
                            ref="placeModal"
                          />

        <div style="margin-bottom: 24px;">
          <a href="" class="light-link" style="font-size: 14px;" v-on:click.prevent="activePage = 'additional'">
            {{ I18n.t('sport_events.new_form.place.additional_info_link') }}
          </a>
        </div>

        <div class="submit-button-wrap">
          <a href="" class="btn cta-btn purple" v-on:click.prevent.stop="sendResponse()">
            {{ I18n.t("common.buttons.save") }}
          </a>
        </div>
      </div>

      <div class="new-place-success-info" v-else>
        <div class="new-place-success-info__image">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <ellipse style="fill:#32BEA6;" cx="256" cy="256" rx="256" ry="255.832"/>
          <polygon style="fill:#FFFFFF;" points="235.472,392.08 114.432,297.784 148.848,253.616 223.176,311.52 345.848,134.504
            391.88,166.392 "/>
          </svg>
        </div>

        <div class="new-place-success-info__message">
          {{ I18n.t('sport_events.new_place.success_message') }}
        </div>

        <div class="new-place-success-info__button-wrap">
          <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
            {{ I18n.t("common.buttons.close") }}
          </a>
        </div>
      </div>
    </div>
    <div class="additional-info"  v-else>
      <div class="page-title" style="margin-bottom: 0px; font-size: 16px;">
        {{ I18n.t('sport_events.new_form.place.additional_info_title') }}
      </div>
      <div style="margin-bottom: 36px; font-size: 13px; color: grey;">
        {{ I18n.t('sport_events.new_form.place.additional_info_description') }}
      </div>

      <div class="form-group">
        <label for="my-file">{{ I18n.t('sport_events.new_form.place.choose_image_lable') }}</label>
        <input type="file" accept="image/*" multiple="multiple" @change="previewMultiImage" class="form-control-file" id="my-file">

        <div class="border p-2 mt-3" v-if="preview_list.length > 0">
          <p>{{ I18n.t('sport_events.new_form.place.image_preview_title') }}</p>
          <template v-if="preview_list.length">
            <div class="d-flex row">
              <div v-for="item, index in preview_list" :key="index" class="m-2 preview-image-wrap">
                <img :src="item" style="max-width: 80px; max-height: 80px;"  />
                <a href="" v-on:click.prevent="removeImage(index)">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="122.881px" height="122.88px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M61.44,0c33.933,0,61.441,27.507,61.441,61.439 c0,33.933-27.508,61.44-61.441,61.44C27.508,122.88,0,95.372,0,61.439C0,27.507,27.508,0,61.44,0L61.44,0z M81.719,36.226 c1.363-1.363,3.572-1.363,4.936,0c1.363,1.363,1.363,3.573,0,4.936L66.375,61.439l20.279,20.278c1.363,1.363,1.363,3.573,0,4.937 c-1.363,1.362-3.572,1.362-4.936,0L61.44,66.376L41.162,86.654c-1.362,1.362-3.573,1.362-4.936,0c-1.363-1.363-1.363-3.573,0-4.937 l20.278-20.278L36.226,41.162c-1.363-1.363-1.363-3.573,0-4.936c1.363-1.363,3.573-1.363,4.936,0L61.44,56.504L81.719,36.226 L81.719,36.226z"/></g></svg>
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>

      <vue-form-generator :model="newPlace"
                          :schema="this.additionalPlaceTabSchema"
                          :options="this.formOptions"
                          ref="placeModal"
                        />

      <div style="margin-bottom: 36px; margin-top: 24px;">
        <a href="" class="light-link" style="font-size: 14px;" v-on:click.prevent="activePage = 'main'">
          <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1L1 6L6 10.5" stroke="#5D98AE"/>
          </svg>

          {{ I18n.t('sport_events.new_form.place.back_link') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import { validators } from "vue-form-generator"

  export default {
    props: {
    },
    data: function() {
      return {
        errors: null,
        responseCompleted: false,
        newPlace: { image_list: [] },
        activePage: "main",
        preview: null,
        image: null,
        preview_list: [],
        formOptions: {
          validationErrorClass: "has-error",
          validationSuccessClass: "has-success",
          validateAfterChanged: true,
        },
        placeTabSchema: {
          fields: [
            {
              type: "input",
              label: I18n.t("sport_events.new_form.labels.place"),
              inputType: "string",
              model: "place_name",
              styleClasses: "col-xs-12",
              required: true,
              validator: validators.string,
              attributes: {
                autocomplete: "off",
              },
              selectOptions: {
                noneSelectedText: I18n.t("sport_events.new_form.non_selected_text.place"),
              },
            }
          ]
        },
        additionalPlaceTabSchema: {
          fields: [
            {
              type: "input",
              label: I18n.t("sport_events.new_form.labels.telephone"),
              hint: I18n.t("sport_events.new_form.hints.telephone"),
              inputType: "string",
              model: "telephone",
              styleClasses: "col-xs-12",
              required: false,
              validator: validators.string,
              attributes: {
                autocomplete: "off"
              }
            },
            {
              type: "input",
              label: I18n.t("sport_events.new_form.labels.website"),
              hint: I18n.t("sport_events.new_form.hints.website"),
              inputType: "string",
              model: "website",
              styleClasses: "col-xs-12",
              required: false,
              validator: validators.string,
              attributes: {
                autocomplete: "off",
                placeholder: "https://"
              }
            }
          ]
        },
      }
    },
    mounted() {
      if (!this.$refs.autocomplete) {
        return
      }

      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ["address"], language: "en" }
      );

      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();

        let ac = place.address_components;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();

        let cityName = _.find(place.address_components, function(o) {
          return _.isEqual(o.types, ["locality", "political"]) ||
                   _.isEqual(o.types, ["administrative_area_level_3", "political"]) ||
                       _.isEqual(o.types, ["postal_town"])
        });

        this.newPlace.latitude = lat;
        this.newPlace.longitude = lon;
        this.newPlace.place_id = place.place_id;
        this.newPlace.formatted_address = place.formatted_address;

        if(cityName) {
          this.newPlace.city_name = cityName["long_name"];
        }
      });
    },
    methods: {
      sendResponse() {
        this.errors = null

        if (!this.newPlace.place_id) {
          this.errors = I18n.t("sport_events.errors.address_not_selected")
          return
        }

        let formData = new FormData()
        this.newPlace.image_list.forEach(function(item, index) {
          formData.append("images[]", item)
        })

        for (let key in this.newPlace) {
          if (key !== 'image_list') {
            formData.append(key, this.newPlace[key])
          }
        }

        axios
            .post("/api/v1/sport_event_places/", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
              this.responseCompleted = true
              this.$eventHub.$emit("new-sport-event-place", response.data)

              this.$emit("close")
            })
            .catch(error => {
              this.errors = I18n.t("sport_events.errors.address_error")
            })
      },
      previewMultiImage: function(event) {
        const input = event.target;
        let cnt = input.files.length;
        let index = 0;
        if (input.files) {
          while(cnt--) {
            let reader = new FileReader();
            reader.onload = (e) => {
              this.preview_list.push(e.target.result);
            }
            this.newPlace.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
            index++;
          }
        }
      },
      reset: function() {
        this.image = null;
        this.preview = null;
        this.newPlace.image_list = [];
        this.preview_list = [];
      },
      removeImage(i) {
        this.newPlace.image_list.splice(i, 1);
        this.preview_list.splice(i, 1);
      }
    }
  }
</script>

<style scoped>
  .place-inputs {
    height: 100%;
    height: 80%;
    margin: 20px auto;

    width: 80%;

    @media only screen and (max-width: 600px) {
      width: 300px;
    }
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .errors {
    color: #e65328;
    text-align: center;
    padding-bottom: 20px;
  }

  .preview-image-wrap {
    position: relative;
  }

  .preview-image-wrap svg {
    width: 25px;
    height: 25px;
    position: absolute;
    right: -10px;
    top: -15px;
  }

  .new-place-success-info {
    text-align: center;
  }

  .new-place-success-info__image {
    margin: 20px;
  }

  .new-place-success-info svg {
    width: 80px;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }

  .new-place-success-info__button-wrap {
    margin-top: 40px;
  }

  .submit-button-wrap {
    text-align: center;
  }
</style>
