<template>
  <nav class="tabs">
    <a v-for="tab in tabs" :class="tabClassName(tab)" :href="tab.href">
      {{ I18n.t(`top_tabs.${tab.code}`) }}
    </a>
  </nav>
</template>

<script>
  export default {
    props: {
      tabs: Array,
    },
    data: function() {
      return {
      }
    },
    methods: {
      tabClassName(tab) {
        if (tab.selected) {
          return "selected"
        }

        return ""
      }
    }
  }
</script>

<style scoped>
  .tabs {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid #e1e4e8;
    margin-bottom: 10px;
  }

  .tabs>a:first-child {
    border-left: 0;
  }

  .tabs>a.selected {
    background-color: #fff;
    border-bottom-color: #fff;
    border-left: 1px solid #dfe2e5;
    border-right: 1px solid #dfe2e5;
    color: #444d56;
  }

  .tabs>a {
    border-bottom: 1px solid #dfe2e5;
    display: table-cell;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
    text-align: center;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  a {
    color: #0366d6;
    text-decoration: none;
  }
</style>
