<template>
  <div class="reject-participant-wrap">
    <div class="title">{{ I18n.t("sport_events.reject_participation.title") }}</div>

    <div v-if="responseCompleted" class="completed-info-wrap">
      <div class="completed-info">
        <div class="completed-info-img">
          <img src="../../../images/success.png">
        </div>
        <div>
          {{ I18n.t("sport_events.reject_participation.success_completed") }}
        </div>
      </div>

      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.close") }}
        </a>
      </div>
    </div>

    <div v-else>
      <div class="textarea-wrap" style="margin-top: 20px;">
        <textarea class="form-control" id="review-textarea" v-model="refuseMessage" :placeholder="I18n.t('sport_events.reject_participation.placeholder')" rows="5" maxlength="300"></textarea>
      </div>
      <div class="submit-button-wrap">
        <a href="" class="btn cta-btn purple" v-on:click.prevent.stop="participationReject()">
          {{ I18n.t("common.buttons.participation_reject") }}
        </a>

        <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="$emit('close')">
          {{ I18n.t("common.buttons.back") }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      participantId: Number,
      sportEventId: Number
    },
    data: function() {
      return {
        responseCompleted: false,
        refuseMessage: ''
      }
    },
    computed: {
    },
    methods: {
      participationReject() {
        axios
          .post(
            "/api/v1/sport_event_users/participations/reject",
            { sport_event_user: { sport_event_id: this.sportEventId, refuse_message: this.refuseMessage } }
          )
          .then(response => {
            this.responseCompleted = true
            this.$eventHub.$emit("change-sport-event", response.data["sport_event"])
          })
          .catch(error => {
            showAlert(
              I18n.t("sport_events.show.notifications.failed_participation_reject"),
              "alert"
            )
          })
          .then(() => {
          })
      }
    }
  }
</script>

<style scoped>
  .reject-participant-from-creator {
    height: 100%;
  }

  .reject-participant-wrap {
    height: 80%;
    width: 300px;
    margin: 0px auto;
    margin-top: 20px;
  }

  .completed-info-wrap {
    height: 75%;
    padding-top: 20px;
  }

  .completed-info {
    height: 80%;
    text-align: center;
  }

  .completed-info-img img {
    width: 120px;
    height: 120px;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 450;
    padding-top: 15px;
    font-weight: bold;
  }

  .submit-button-wrap {
    text-align: center;
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }

  .submit-button-wrap .green {
    margin-right: 10px;
  }
</style>
