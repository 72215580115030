<template>
  <div v-if="user.can_remind_email_confirmation">
    <div class="alert alert-success alert-dismissible" v-if="this.emailIsSent">
      {{ I18n.t("users.email_confirmation_alert.success_text") }}
    </div>
    <div class="alert alert-warning alert-dismissible" v-else>
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <strong>{{ I18n.t("users.email_confirmation_alert.warning") }}</strong>
      {{ I18n.t("users.email_confirmation_alert.text") }}
      <a href="" class="send-email-link" v-on:click.prevent="sendEmail()">{{ I18n.t("users.email_confirmation_alert.send_link") }}</a>
    </div>
  </div>

  <div class="alert alert-warning alert-dismissible" v-else-if="user.email_sent_and_not_confirmed">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    {{ I18n.t("users.email_confirmation_alert.sent") }}
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      user: Object,
    },
    data() {
      return {
        emailIsSent: false,
        localUser: this.user
      }
    },
    methods: {
      sendEmail() {
        axios
          .post(
            "/email/confirmations"
          )
          .then(response => {
            this.emailIsSent = true
          })
          .catch(error => {
            //this.errors = true
          })
      }
    }
  }
</script>

<style scoped>
  .send-email-link {
    font-weight: bolder;
    font-size: 14px !important;
  }
</style>
