<template>
  <a :href="this.sportEventUrl()" :class="this.wrapClass()">
    <div v-if="!!this.notification.sport_event_name" class="event-name">
      <a :href="this.sportEventUrl()">
        {{ this.notification.sport_event_name }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span>
          {{ this.title }}
          {{
            I18n.t(`sport_events.notifications.sport_event_status_changed.${this.notification.data.status}`)
          }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    methods: {
      wrapClass() {
        let defaultClass = "notification-item-wrap"

        if(this.notification.data.status == "canceled")
          return `${defaultClass} notification-item-wrap_error`
        else if(this.notification.data.status == "ready") {
          return `${defaultClass} notification-item-wrap_ready`
        }
        else {
          return `${defaultClass} notification-item-wrap_success`
        }
      },
      sportEventUrl() {
        if (!!this.reviewLink) {
          return `${this.notification.data.sport_event_url}/review`
        } else {
          return this.notification.data.sport_event_url
        }
      }
    },
    computed: {
      title() {
        return I18n.t(`sport_events.notifications.sport_event_status_changed_link_title.${this.notification.data.status}`)
      },
      reviewLink() {
        console.log("status, id", this.notification.data.status, this.notification.data.sport_event_url)
        return I18n.t(`sport_events.notifications.sport_event_status_changed_review.${this.notification.data.status}`)
      }
    }
  }
</script>

<style scoped>
  .status {
    padding: 2px;
  }
</style>
