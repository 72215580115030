<template>
  <div class="telegram-subscription-wrap">
    <div class="telegram-subscription">
      <div class="title">{{ I18n.t("common.info_modals.telegram.title") }}</div>
      <div class="info">{{ I18n.t("common.info_modals.telegram.info") }}</div>
      <a target="_blank" href="https://t.me/SpottySpoBot">t.me/spottybot</a>
    </div>

    <div class="close-button-wrap">
      <a href="" class="btn cta-btn cancel" v-on:click.prevent.stop="sendResponse()">
        {{ I18n.t("common.buttons.close") }}
      </a>
    </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
    },
    data: function() {
      return {
      }
    },
    computed: {
    },
    methods: {
      sendResponse() {
        this.$emit("close")
        axios
          .post("/api/v1/users/telegram_subscriptions", {})
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style scoped>
  .telegram-subscription-wrap {
    height: 100%;
    padding: 25px;

    @media only screen and (min-width: 600px) {
      padding: 40px;
    }
  }

  .telegram-subscription {
    height: 80%;
    padding-top: 20px;
    text-align: center;
  }

  .title {
    font-weight: bold;
  }

  .info {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .cta-btn {
    display: inline-block;
    padding: 4px 15px;
    line-height: 30px;
    text-decoration: none;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .cta-btn.cancel {
    color: grey;
    border: 1px solid grey;
  }

  .close-button-wrap {
    text-align: center;

    @media only screen and (max-width: 600px) {
      margin-top: 7px;
      margin-bottom: 20px;
    }
  }
</style>
