<template>
  <a :href="this.notification.data.profile_url" class="notification-item-wrap notification-item-wrap_red_cup">
    <div class="event-name">
      <a :href="this.notification.data.profile_url">
        {{ I18n.t("sport_competitions.title") }}
      </a>
    </div>

    <div>
      <div class="notification-item">
        <div class="notification-item-message">
          <span class="notification-item-img">
            <img :src="this.notification.data.profile_image" v-if="!!this.notification.data.profile_image">
            <span v-else class="nickname">@{{ this.notification.data.profile_nickname }}</span>
          </span>
          <span class="notification-item-img-msg">
            {{ I18n.t("sport_competitions.notifications.sport_competition_challenge_declined") }}
          </span>
        </div>
      </div>

      <div v-if="!!this.notification.data.decline_message" class="decline-message">
        {{ this.notification.data.decline_message }}
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
  .decline-message {
    margin-top: 5px;
    font-weight: 400;
    font-style: italic;
  }
</style>
