import { render, staticRenderFns } from "./new_form.vue?vue&type=template&id=95d6d030&scoped=true&"
import script from "./new_form.vue?vue&type=script&lang=js&"
export * from "./new_form.vue?vue&type=script&lang=js&"
import style0 from "./new_form.vue?vue&type=style&index=0&id=95d6d030&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d6d030",
  null
  
)

export default component.exports