<template>
  <div class="share-link-modal">
    <div class="title">{{ I18n.t("sport_events.share_link.title") }}</div>

    <div class="input-wrap">
      <input type="text" id="copy-link-input" class="form-control" v-model="this.participation_link">
      <div class="hint">
        {{ I18n.t("sport_events.share_link.hint") }}
      </div/>
    </div>

    <div class="clipboard-link-wrap">
      <div style="display: inline-block;">
        <a href="" v-on:click.prevent.stop="copyLink()" class="btn cta-btn green btn-sm">{{ I18n.t("sport_events.share_link.copy_to_clipboard") }}</a>
      </div>

      <div class="success-message" v-show="success">
        {{ I18n.t("sport_events.share_link.success") }}
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      sport_event_id: Number,
      participation_link: String,
    },
    data: function() {
      return {
        success: false
      }
    },
    mounted() {
      document.getElementById("copy-link-input").select()
    },
    methods: {
      copyLink() {
        try {
          document.execCommand("copy");
          this.success = true
          this.$eventHub.$emit("sport-event-link-copied", false)

          axios
            .post(`/api/v1/sport_events/${this.sport_event_id}/update_share_link_notification`)
            .then(response => {
            })
            .catch(error => {
              console.log(error)
            })
        } catch (err) {
          console.log("Oops, unable to copy");
        }
      }
    }
  }
</script>

<style scoped>
  .share-link-modal {
    padding: 40px;
  }

  .title {
    box-sizing: border-box;
    width: 100%;
    font-size: 20px;
    font-weight: 450;
    margin-bottom: 20px;
  }

  .success-message {
    color: green;
    margin-left: 15px;
    display: inline-block;
    font-size: 13px;
  }

  .clipboard-link-wrap {
    margin: 30px auto;
  }

  .cta-btn.green {
    background-color: #0c6035;
    color: #fff;
  }

  .hint {
    font-style: italic;
    font-size: .8em;
    margin-top: 3px;
  }
</style>
