<template>
  <div class="user-sports-list">
    <a class="back-link" :href="this.backLink()">
      <div class="back-link-wrap" v-if="$mq != 'sm'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>
    <div class="user-sports-title-wrap">
      <div class="user-sports-title" v-if="$mq != 'sm'">
       {{ this.titleTranslation() }}
      </div>
      <div class="user-sports-settings-wrap" v-if="this.isCurrentUser() && Object.keys(this.user.sports).length > 0 && $mq != 'sm'">
        <a href="/user_settings/sports" class="btn cta-btn full-button usual-button purple">
          <font-awesome-icon icon="cogs" size="lg" />
          {{ I18n.t("users.profile.sports.settings") }}
        </a>
      </div>
    </div>

    <div class="user-sports-settings" v-if="this.isCurrentUser() && Object.keys(this.user.sports).length > 0 && $mq == 'sm'">
      <a href="/user_settings/sports" class="btn cta-btn full-button usual-button purple">
        <font-awesome-icon icon="cogs" size="lg" />
        {{ I18n.t("users.profile.sports.settings") }}
      </a>
    </div>

    <div class="user-sports" v-if="Object.keys(this.user.sports).length > 0">
      <div v-for="(level, sport, index) in this.user.sports" class="user-sport-item">
        <div class="user-sport-title-wrap">
          <div class="user-sport-image-item">
            <div class="sport-item__image" :class="sport"></div>
            <div class="sport-item__level">{{ level }}</div>
          </div>
          <div class="sport-item__right">
            <div class="sport-item__name">{{ I18n.t(`sports.${sport}`) }}</div>
            <div class="sport-item__level-text">{{ I18n.t(`sport_events.levels.${level.toLowerCase()}`) }}</div>
          </div>
        </div>
        <div class="user-sport-schedules" v-if="Object.keys(user.sport_schedules).length > 0">
          <div class="user-sport-schedules-items-block">
            <div class="user-sport-schedules-items" v-for="(schedules, weekDay) in user.sport_schedules[sport]">
              <div class="user-sport-schedules-item-wrap">
                <div class="user-sport-schedules-item" >
                  <div class="user-sport-schedules-items__weekday">{{ I18n.t(`common.week_days.${weekDay}`) }}</div>
                  <div class="user-sport-schedules-item__schedule" v-for="schedule in schedules">
                    {{ `${schedule.time_from} - ${schedule.time_to}` }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a href="/user_settings/sports" class="btn cta-btn purple full-button" v-if="this.isCurrentUser() && Object.keys(this.user.sports).length == 0">
      {{ I18n.t("sport_events.settings_button_set_up") }}
    </a>
  </div>
</template>

<script>

  export default {
    props: {
      current_user: Object,
      user: Object,
    },
    data: function() {
      return {
        currentUser: this.current_user
      }
    },
    mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
      isCurrentUser() {
        return this.user.id == this.currentUser.id
      },
      titleTranslation() {
        if(this.isCurrentUser()) {
          return I18n.t("users.titles.my_sports")
        } else {
          return I18n.t("users.titles.other_sports", { nickname: this.user.nickname })
        }
      },
      backLink() {
        if (this.isCurrentUser()) {
          return "/"
        } else {
          return `/users/${this.user.id}`
        }
      }
    }
  }
</script>

<style scoped>
  .user-sports-list {
    padding: 0px 15px;
    padding-top: 67px;
  }

  .user-sports-wrap {
    margin-top: 24px;
  }

  .user-sports-title-wrap {
    display: flex;
    justify-content: space-between;
  }

  .user-sports-title-wrap a {
    font-size: 13px;
  }

  .user-sports-settings {
    margin-top: 24px;
  }

  .user-sports-settings a {
    font-size: 14px;
  }

  .back-link-wrap {
    margin-bottom: 36px;
  }

  .back-link {
    text-decoration: none;
  }

  .back-link svg {
    margin-right: 5px;
  }

  .profile-navigation-link {
    color: #5D98AE;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }

  .user-sport-title-wrap {
    display: flex;
  }

  .user-sport-image-item {
    position: relative;
    /*background-color: #6460AC;*/
    background-color: #7d79b9;
    height: 50px;
    width: 50px;
    border-radius: 10px;
  }

  .sport-item__right {
    margin-left: 24px;
  }

  .user-sports-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .user-sports-more-link a {
    text-transform: lowercase;
    font-weight: 500;
  }

  .user-avatar {
    position: absolute;
    top: 50px;
    left: 13px;
  }

  .user-sports {
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .user-sports-title {
    font-size: 18px;
    font-weight: bold;
  }

  .sport-item__name {
    font-size: 15px;
    font-weight: 500;
  }

  .sport-item__level {
    position: absolute;
    bottom: 1px;
    right: -5px;
    font-size: 11px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sport-item__level-text {
    font-size: 13px;
    color: grey;
    margin-top: 2px;
  }

  .user-sport-schedules-items-block {
    display: flex;
    margin-left: 62px;
  }

  .user-sport-schedules-item__schedule {
    margin-top: 6px;
    font-size: 12px;
  }

  .user-sport-schedules-items__weekday {
    font-weight: 450;
    text-transform: lowercase;
  }

  /* common */

  .full-button {
    width: 100%;
    font-weight: 450;
    font-size: 14px;
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
  }

  .user-sports {
  }

  .user-sports a {
    font-size: 12px;
  }

  .user-sport-schedules {
  }

  .user-sport-schedules a {
    font-size: 11px;
    text-decoration: none;
  }

  .user-sport-list {
    display: flex;
  }

  .user-sport-item {
    margin-top: 24px;
    padding: 15px;
    border: 1px solid gainsboro;
    border-radius: 10px;
  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .user-sport-schedules-items {
    padding: 12px;
    padding-bottom: 0px;
    font-size: 13px;
    padding-top: 0px;
  }

  .user-sport-schedules-items a {
    margin-bottom: 10px;
    margin-right: 2px;
  }

  .user-sport-schedules-items__sport {
    color: #946e1e;
    font-weight: 500;
    width: 100px;
  }

  .user-sport-schedules-items {
    display: flex;
  }

  .user-sport-schedules-item {
    margin-bottom: 10px;
  }

  .user-sport-schedules-items__weekday {
    font-weight: 500;
  }
</style>
