<template>
  <div class="place-map">
    <div id="map"></div>
  </div>
</template>

<script>
  import axios from "axios"
  import { validators } from "vue-form-generator"

  export default {
    props: {
      sportEventLatitude: Number,
      sportEventLongitude: Number
    },
    data: function() {
      return {
      }
    },
    mounted() {
      const placeCoords = { lat: this.sportEventLatitude, lng: this.sportEventLongitude };

      console.log(placeCoords)

      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 10,
        center: placeCoords,
      });

      const marker = new google.maps.Marker({
        position: placeCoords,
        map: map,
      });
    }
  }
</script>

<style scoped>
  .place-map {
    width: 100%;
    height: 100%;
  }

  #map {
    width: 100%;
    height: 100%;
  }
</style>
